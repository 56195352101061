import React from 'react';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate, } from 'react-router-dom';
import App from '@/App';
import Archive from '@/pages/Archive';
import Companies from '@/pages/Companies';
import Groups from '@/pages/Groups';
import Login from '@/pages/Login';
import Logs from '@/pages/Logs';
import Mailing from '@/pages/Mailing';
import Map from '@/pages/Map';
import Monitoring from '@/pages/Monitoring';
import Offices from '@/pages/Offices';
import PreRecord from '@/pages/PreRecord';
import Reports from '@/pages/Reports';
import Settings from '@/pages/Settings';
// import TableMonitoring from '@/pages/TableMonitoring';
import Users from '@/pages/Users';
import Widget from '@/pages/Widget';
import Widgets from '@/pages/Widgets';
import paths from '@/utils/paths';
var root = ReactDOM.createRoot(document.getElementById('root'));
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});
dayjs.extend(localeData);
dayjs.locale('ru');
root.render(<ConfigProvider locale={ru_RU} theme={{
        token: {
            colorPrimary: '#05588f',
        },
    }}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route element={<Login />} path="/login/"/>
          <Route element={<App />} path="/">
            <Route index element={<Navigate to="/monitoring/"/>}/>
            <Route element={<Monitoring />} path={paths.monitoring}/>
            {/* <Route element={<TableMonitoring />} path={paths.tableMonitoring} /> */}
            <Route element={<Map />} path={paths.map}/>
            <Route element={<Companies />} path={paths.companies}/>
            <Route element={<Offices />} path={paths.offices}/>
            <Route element={<Users />} path={paths.users}/>
            <Route element={<Reports />} path={paths.reports}/>
            <Route element={<Settings />} path={paths.settings}/>
            <Route element={<Archive />} path={paths.archive}/>
            <Route element={<Groups />} path={paths.groups}/>
            <Route element={<Mailing />} path={paths.mailing}/>
            <Route element={<Logs />} path={paths.logs}/>
            <Route element={<Widgets />} path={paths.widgets}/>
            <Route element={<Widget />} path="/widget/:id"/>
            <Route element={<PreRecord />} path={paths.prerecord}/>
          </Route>
          <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>);
