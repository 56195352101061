var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
import { dateTimeFormat, timeFormat } from '@/utils/constants';
import getColumnSearchProps from '@/utils/tableSearch';
var statuses = {
    3: 'Закрыт',
    4: 'Закрыт по неявке',
    9: 'Удалён системой',
};
export var getColumns = function () { return [
    __assign(__assign({ title: 'Оператор', dataIndex: 'operator_fio', width: 360 }, getColumnSearchProps('operator_fio', 'по оператору')), { sorter: function (a, b) { return ([
            a.operator_fio.toLowerCase(),
            b.operator_fio.toLowerCase(),
        ].sort()[0] === a.operator_fio.toLowerCase() ? -1 : 1); } }),
    __assign(__assign({ title: 'Отделение', dataIndex: 'office_name', width: 360 }, getColumnSearchProps('office_name', 'по офису')), { sorter: function (a, b) { return ([
            a.office_name.toLowerCase(),
            b.office_name.toLowerCase(),
        ].sort()[0] === a.office_name.toLowerCase() ? -1 : 1); } }),
    {
        title: 'Всего обслужено',
        dataIndex: 'ticket_count',
        key: 'ticket_count',
        sorter: function (a, b) { return Number(a.ticket_count) - Number(b.ticket_count); },
    },
    {
        title: 'Общая длительность обслуживания',
        dataIndex: 'total_proc',
        key: 'total_proc',
        render: function (_, row) { return dayjs.utc(Number(row.total_proc) * 1000).format(timeFormat); },
        sorter: function (a, b) { return Number(a.total_proc) - Number(b.total_proc); },
    },
    {
        title: 'Средняя длительность обслуживания',
        dataIndex: 'avg_proc',
        key: 'avg_proc',
        render: function (_, row) { return dayjs.utc(Number(row.avg_proc) * 1000).format(timeFormat); },
        sorter: function (a, b) { return Number(a.avg_proc) - Number(b.avg_proc); },
    },
    {
        title: 'Средняя длительность ожидания',
        dataIndex: 'avg_wait',
        key: 'avg_wait',
        render: function (_, row) { return dayjs.utc(Number(row.avg_wait) * 1000).format(timeFormat); },
        sorter: function (a, b) { return Number(a.avg_wait) - Number(b.avg_wait); },
    },
]; };
export var getInfoTableColumns = function () { return [
    {
        title: 'Дата постановки в очередь',
        dataIndex: 'create_date',
        render: function (_, row) { return dayjs.utc(row.create_date).format(dateTimeFormat); },
        sorter: function (a, b) { return (dayjs(a.create_date).isAfter(dayjs(b.create_date)) ? 1 : -1); },
    },
    {
        title: '№ талона',
        dataIndex: 'ticket_number',
        sorter: function (a, b) { return ([
            a.ticket_number.toLowerCase(),
            b.ticket_number.toLowerCase(),
        ].sort()[0] === a.ticket_number.toLowerCase() ? -1 : 1); },
    },
    {
        title: 'Рабоче место',
        dataIndex: 'workplace_name',
        width: 200,
        sorter: function (a, b) { return ([
            a.workplace_name.toLowerCase(),
            b.workplace_name.toLowerCase(),
        ].sort()[0] === a.workplace_name.toLowerCase() ? -1 : 1); },
    },
    {
        title: 'Приоритет',
        dataIndex: 'prior',
        render: function (_, row) { return (row.prior < 100 ? 'обычный' : 'высокий'); },
    },
    {
        title: 'Дата окончания обслуживания',
        dataIndex: 'close_date',
        render: function (_, row) { return dayjs.utc(row.close_date).format(dateTimeFormat); },
        sorter: function (a, b) { return (dayjs(a.close_date).isAfter(dayjs(b.close_date)) ? 1 : -1); },
    },
    {
        title: 'Время ожидания',
        dataIndex: 'wait_time',
        render: function (_, row) { return dayjs.utc(Number(row.wait_time) * 1000).format(timeFormat); },
        sorter: function (a, b) { return Number(a.wait_time) - Number(b.wait_time); },
    },
    {
        title: 'Время обслуживания',
        dataIndex: 'proc_time',
        render: function (_, row) { return dayjs.utc(Number(row.proc_time) * 1000).format(timeFormat); },
        sorter: function (a, b) { return Number(a.proc_time) - Number(b.proc_time); },
    },
    {
        title: 'Статус',
        dataIndex: 'close_status',
        render: function (_, row) { return statuses[Number(row.close_status)]; },
    },
    {
        title: 'Оценка',
        dataIndex: 'mark',
    },
]; };
