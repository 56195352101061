var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, notification, Select, Skeleton, TimePicker, } from 'antd';
import { useMutation, useQuery } from 'react-query';
import preorder from '@/utils/rest/preorder';
function MultipleOfficeSlots() {
    var _a = useState(), queueItems = _a[0], setQueueItems = _a[1];
    var _b = useState(false), forAll = _b[0], setForAll = _b[1];
    var _c = useState(false), copy = _c[0], setCopy = _c[1];
    var _d = useState('single'), createType = _d[0], setCreateType = _d[1];
    var offices = useQuery('preorderOffices', preorder.getOffices, {
        enabled: false,
    }).data;
    var days = useQuery('multipleDays', preorder.getDaysForAll).data;
    var isLoading = useQuery('multipleQueues', function () { return preorder.getQueues(0); }, {
        onSuccess: function (data) {
            var items = [];
            Object.entries(data).forEach(function (el) {
                var key = el[0], value = el[1];
                items.push({
                    key: "".concat(key, "_").concat(value.qId),
                    label: key,
                    offices: value.offices || [],
                });
            });
            setQueueItems(items);
        },
    }).isLoading;
    var _e = useMutation({
        mutationFn: function (variables) { return preorder.createSlotsForAll(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Слоты успешно созданы',
            });
        },
        onError: function () {
            notification.error({
                message: 'Не удалось создать слоты',
            });
        },
    }), mutate = _e.mutate, isCreateLoading = _e.isLoading;
    var finishForm = function (values) {
        var queues = values.forAll ? queueItems : queueItems === null || queueItems === void 0 ? void 0 : queueItems.filter(function (el) { return values.queues.includes(String(el.key)); });
        var time = Array.isArray(values.time)
            ? [values.time[0].format('HH:mm'), values.time[1].format('HH:mm')]
            : values.time.format('HH:mm');
        if (days) {
            var data = __assign(__assign({}, values), { date: Array.isArray(values.date)
                    ? [values.date[0].add(1, 'day').format('YYYY.MM.DD'), values.date[1].format('YYYY.MM.DD')]
                    : values.date.format('YYYY.MM.DD'), day: Array.isArray(values.date)
                    ? days[values.date[0].format('DD.MM.YYYY')]
                    : days[values.date.format('DD.MM.YYYY')], time: time, queues: queues });
            mutate(data);
        }
    };
    var onValuesChange = function (_changed, values) {
        if (values.forAll !== undefined) {
            setForAll(Boolean(values.forAll));
        }
        if (values.type !== undefined) {
            setCreateType(String(values.type));
        }
        if (values.copy !== undefined) {
            setCopy(Boolean(values.copy));
        }
    };
    return isLoading
        ? <Skeleton />
        : (<Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={finishForm} onValuesChange={onValuesChange} style={{ width: '1000px', margin: 'auto', lineHeight: '2px' }} labelWrap initialValues={{
                interval: 15,
                count: 5,
                type: 'single',
                replace: false,
            }}>
        <Form.Item label="Очередь" name="queues" rules={!forAll
                ? [{
                        required: true,
                        message: 'Выберите очередь или поставьте отметку для добавления слотов во все очереди',
                    }]
                : []}>
          <Select optionFilterProp="label" placeholder="Выберите очередь" disabled={forAll} mode="multiple">
            {queueItems === null || queueItems === void 0 ? void 0 : queueItems.map(function (el) { return (<Select.Option key={el.key} label={el.label}>{el.label}</Select.Option>); })}
          </Select>
        </Form.Item>
        <Form.Item label="Добавить слоты по всем услугам" name="forAll" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item label="Исключить офисы" name="offices">
          <Select optionFilterProp="label" placeholder="Выберите офисы в которых не нужно создавать слоты" mode="multiple">
            {offices === null || offices === void 0 ? void 0 : offices.map(function (office) { return (<Select.Option key={Number(office.id)} label={office.name}>{office.name}</Select.Option>); })}
          </Select>
        </Form.Item>
        <Form.Item valuePropName="checked" name="copy" label="Копировать слоты">
          <Checkbox />
        </Form.Item>
        {copy && createType === 'single' && (<Form.Item valuePropName="checked" name="replace" label="Перезаписать слоты">
            <Checkbox />
          </Form.Item>)}
        <Form.Item label="Тип добавления слотов" name="type">
          <Select placeholder="Выберите тип добавления слотов">
            <Select.Option key="single">Одиночное</Select.Option>
            <Select.Option key="multiple">Множественное</Select.Option>
          </Select>
        </Form.Item>
        {createType === 'single' && (<>
            <Form.Item label="Выберите дату" name="date">
              {copy
                    ? (<DatePicker.RangePicker format="DD.MM.YYYY" placeholder={['Выберите дату начала', 'Выберите дату окончания']} style={{ width: '100%' }} showNow={false}/>)
                    : (<DatePicker format="DD.MM.YYYY" placeholder="Выберите дату" style={{ width: '100%' }} showNow={false}/>)}
            </Form.Item>
            <Form.Item label="Выберите время" name="time">
              <TimePicker format="HH:mm" minuteStep={5} placeholder="Выберите время" style={{ width: '100%' }} showNow={false}/>
            </Form.Item>
          </>)}
        {createType === 'multiple' && (<>
            <Form.Item name="date" label="Выберите день" rules={[{ required: true, message: 'Выберите день' }]}>
              {copy
                    ? (<DatePicker.RangePicker format="DD.MM.YYYY" placeholder={['Выберите дату начала', 'Выберите дату окончания']} style={{ width: '100%' }} showNow={false}/>)
                    : (<DatePicker format="DD.MM.YYYY" placeholder="Выберите дату" style={{ width: '100%' }} showNow={false}/>)}
            </Form.Item>
            <Form.Item name="interval" label="Интервал времени (минуты)" rules={[{ required: true, message: 'Введите интервал добавления слотов' }]}>
              <Input type="number" step={5} min={5}/>
            </Form.Item>
            <Form.Item name="count" label="Кол-во слотов на одно время" rules={[{ required: true, message: 'Введите количество слотов' }]}>
              <Input type="number" min={1}/>
            </Form.Item>
            <Form.Item label="Интервал времени" name="time" rules={[{ required: true, message: 'Выберите интервал для добавления слотов' }]}>
              <TimePicker.RangePicker format="HH:mm" style={{ width: '320px' }} minuteStep={5} showNow={false}/>
            </Form.Item>
            <Form.Item valuePropName="checked" name="replace" label="Перезаписывать существующие слоты">
              <Checkbox />
            </Form.Item>
          </>)}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={isCreateLoading} size="large" type="primary" htmlType="submit">Добавить слоты</Button>
        </Form.Item>
      </Form>);
}
export default MultipleOfficeSlots;
