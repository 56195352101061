var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Drawer, Form, Input, Modal, notification, Select, Table, } from 'antd';
import { useMutation, useQuery } from 'react-query';
import getColumns from '@/pages/Mailing/tableColumns';
import mailing from '@/utils/rest/mailing';
import offices from '@/utils/rest/offices';
function Mailing() {
    var _a = useState(undefined), currentRow = _a[0], setCurrentRow = _a[1];
    var _b = useState(false), isDrawerOpen = _b[0], setIsDrawerOpen = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var toggleDrawer = function () {
        if (isDrawerOpen) {
            setCurrentRow(undefined);
        }
        setIsDrawerOpen(function (prevState) { return !prevState; });
    };
    var toggleModal = function () {
        if (isModalOpen) {
            setCurrentRow(undefined);
        }
        setIsModalOpen(function (prevState) { return !prevState; });
    };
    var _d = useQuery('offices', offices.getAll), officeList = _d.data, isOfficeLoading = _d.isLoading;
    var _e = useQuery('mailing', mailing.get, {
        enabled: !isOfficeLoading,
    }), data = _e.data, isLoading = _e.isLoading, refetch = _e.refetch;
    var createReceiver = useMutation({
        mutationFn: function (variables) { return mailing.create(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Получатель успешно добавлен',
            });
            toggleDrawer();
            refetch();
            setCurrentRow(undefined);
        },
        onError: function () {
            notification.error({
                message: 'Не удалось добавить получателя, проверьте правильность введённых данных',
            });
        },
    }).mutate;
    var updateReceiver = useMutation({
        mutationFn: function (variables) { return mailing.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Данные получателя успешно обновлены',
            });
            toggleDrawer();
            refetch();
            setCurrentRow(undefined);
        },
        onError: function () {
            notification.error({
                message: 'Не удалось обновить данные получателя',
            });
        },
    }).mutate;
    var deleteReceiver = useMutation({
        mutationFn: function (id) { return mailing.delete(id); },
        onSuccess: function () {
            notification.success({
                message: 'Получатель удалён',
            });
            toggleModal();
            refetch();
            setCurrentRow(undefined);
        },
        onError: function () {
            notification.error({
                message: 'Не удалось удалить получателя',
            });
        },
    }).mutate;
    var handleEdit = function (row) {
        setCurrentRow(row);
        toggleDrawer();
    };
    var handleDelete = function (row) {
        setCurrentRow(row);
        toggleModal();
    };
    var confirmDelete = function () {
        if (currentRow) {
            deleteReceiver(currentRow.id);
        }
    };
    var saveReceiver = function (values) {
        if (currentRow) {
            updateReceiver(__assign(__assign({}, values), { id: currentRow.id }));
        }
        else {
            createReceiver(values);
        }
    };
    return (<>
      <h2 className="page__header">Адреса для рассылки</h2>
      <div className="page__content">
        <Modal title="Удаление получателя отчётов" open={isModalOpen} onOk={confirmDelete} destroyOnClose onCancel={toggleModal}>
          <p>
            Вы уверенны, что хотите удалить получателя
            {' '}
            <span className="modal__text">{currentRow === null || currentRow === void 0 ? void 0 : currentRow.email}</span>
          </p>
          {(currentRow === null || currentRow === void 0 ? void 0 : currentRow.description) && (<p>
            Описание получателя:
            {' '}
            <span className="modal__text">{currentRow === null || currentRow === void 0 ? void 0 : currentRow.description}</span>
          </p>)}
        </Modal>
        {!isOfficeLoading
            && (<Drawer width={600} destroyOnClose open={isDrawerOpen} onClose={toggleDrawer}>
            <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={currentRow} onFinish={saveReceiver}>
              <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Введите адрес электронной почты' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Описание" name="description">
                <Input />
              </Form.Item>
              <Form.Item label="Офисы" name="offices">
                <Select optionFilterProp="label" placeholder="Все офисы" mode="multiple">
                  {officeList && officeList.length > 0 && officeList.map(function (office) { return (<Select.Option label={office.name} key={office.id}>
                      {office.name}
                    </Select.Option>); })}
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <div className="form__buttons">
                  <Button type="primary" htmlType="submit">Сохранить</Button>
                  <Button onClick={toggleDrawer}>Отменить</Button>
                </div>
              </Form.Item>
            </Form>
          </Drawer>)}
        <Button onClick={toggleDrawer} className="add__button" type="primary">Добавить</Button>
        <Table rowKey="id" dataSource={data} loading={isLoading} columns={getColumns(officeList, handleEdit, handleDelete)}/>
      </div>
    </>);
}
export default Mailing;
