import React from 'react';
import { ReactComponent as OfficeIcon } from '@/assets/icons/officeMark.svg';
import { ReactComponent as Mark1 } from '@/assets/icons/smiles/smile_1.svg';
import { ReactComponent as Mark2 } from '@/assets/icons/smiles/smile_2.svg';
import { ReactComponent as Mark3 } from '@/assets/icons/smiles/smile_3.svg';
import { ReactComponent as Mark4 } from '@/assets/icons/smiles/smile_4.svg';
import { ReactComponent as Mark5 } from '@/assets/icons/smiles/smile_5.svg';
import { colors } from '@/utils/constants';
export var hint = function (el) { return ("<div class=\"office__hint\">\n      <p class=\"office__hint__title\">".concat(el.office_name, "</p>\n      <p class=\"office__hint__text\">").concat(el.Office.situation, "</p>\n    </div>")); };
export var getMapIcon = function (waitTime, procTime, timePoints, offline, queueless, queuelessColor) {
    var svgColor = '';
    if (waitTime < timePoints.wait.min) {
        svgColor = timePoints.wait.colors.min;
    }
    if (procTime < timePoints.proc.min) {
        svgColor = timePoints.proc.colors.min;
    }
    if (waitTime > timePoints.wait.min && waitTime < timePoints.wait.max) {
        svgColor = timePoints.wait.colors.mid;
    }
    if (procTime > timePoints.proc.min && procTime < timePoints.proc.max) {
        svgColor = timePoints.proc.colors.mid;
    }
    if (waitTime > timePoints.wait.max) {
        svgColor = timePoints.wait.colors.max;
    }
    if (procTime > timePoints.proc.max) {
        svgColor = timePoints.proc.colors.max;
    }
    if (offline) {
        svgColor = colors.offline;
    }
    if (queueless) {
        svgColor = JSON.parse(queuelessColor).color;
    }
    var svgString = "<svg width=\"28\" height=\"28\" viewBox=\"0 0 28 28\" fill=\"".concat(svgColor, "\" xmlns=\"http://www.w3.org/2000/svg\">\n      <path d=\"M14 24.5C12.5264 23.2431 11.1605 21.8651 9.91665 20.3805C8.04998 18.151 5.83331 14.8307 5.83331 11.6667C5.83166 8.36213 7.8216 5.38229 10.8746 4.11763C13.9275 2.85297 17.4417 3.55278 19.7773 5.8905C21.3132 7.41954 22.1735 9.49945 22.1667 11.6667C22.1667 14.8307 19.95 18.151 18.0833 20.3805C16.8394 21.8651 15.4736 23.2431 14 24.5ZM14 8.16666C12.7496 8.16666 11.5941 8.83376 10.9689 9.91666C10.3437 10.9996 10.3437 12.3338 10.9689 13.4167C11.5941 14.4996 12.7496 15.1667 14 15.1667C15.933 15.1667 17.5 13.5997 17.5 11.6667C17.5 9.73367 15.933 8.16666 14 8.16666Z\" />\n    </svg>");
    var blob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    var url = URL.createObjectURL(blob);
    return {
        iconImageHref: url,
    };
};
export var getListIcons = function (waitTime, procTime, timePoints, offline) {
    var waiteColors = timePoints.wait.colors;
    var procColors = timePoints.proc.colors;
    if (offline)
        return <OfficeIcon style={{ fill: colors.offline }}/>;
    if (waitTime > timePoints.wait.max) {
        return <OfficeIcon style={{ fill: waiteColors.max }}/>;
    }
    if (procTime > timePoints.proc.max) {
        return <OfficeIcon style={{ fill: procColors.max }}/>;
    }
    if (waitTime > timePoints.wait.min && waitTime < timePoints.wait.max) {
        return <OfficeIcon style={{ fill: waiteColors.mid }}/>;
    }
    if (procTime > timePoints.proc.min && procTime < timePoints.proc.max) {
        return <OfficeIcon style={{ fill: procColors.mid }}/>;
    }
    if (waitTime < timePoints.wait.min) {
        return <OfficeIcon style={{ fill: waiteColors.min }}/>;
    }
    if (procTime < timePoints.proc.min) {
        return <OfficeIcon style={{ fill: procColors.min }}/>;
    }
    return <OfficeIcon style={{ fill: colors.normal }}/>;
};
export var getColors = function (time, timePoints) {
    if (time < timePoints.min) {
        return timePoints.colors.min;
    }
    if (time > timePoints.max) {
        return timePoints.colors.max;
    }
    return timePoints.colors.mid;
};
export var getMarkIcons = function (avgMark) {
    var mark = Math.floor(avgMark);
    switch (true) {
        case mark === 1:
            return <Mark1 />;
        case mark === 2:
            return <Mark2 />;
        case mark === 3:
            return <Mark3 />;
        case mark === 4:
            return <Mark4 />;
        case mark === 5:
            return <Mark5 />;
        default:
            return null;
    }
};
