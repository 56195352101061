import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Space, Button } from 'antd';
var handleSearch = function (selectedKeys, confirm) {
    confirm();
};
var handleReset = function (clearFilters, selectedKeys, confirm) {
    clearFilters();
    handleSearch(selectedKeys, confirm);
};
var roles = {
    admin: 'Администратор',
    user: 'Пользователь',
    reader: 'Читатель отчётов',
    checklister: 'Анкетировщик',
};
var getColumnSearchProps = function (dataIndex, title) { return ({
    filterDropdown: function (_a) {
        var setSelectedKeys = _a.setSelectedKeys, selectedKeys = _a.selectedKeys, clearFilters = _a.clearFilters, confirm = _a.confirm;
        var searchInput = useRef(null);
        return (<div style={{
                padding: 8,
            }}>
        <Input ref={searchInput} placeholder={"\u041F\u043E\u0438\u0441\u043A ".concat(title)} value={selectedKeys[0]} onChange={function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); }} onPressEnter={function () { return handleSearch(selectedKeys, confirm); }} style={{
                marginBottom: 8,
                display: 'block',
            }}/>
        <Space>
          <Button type="primary" onClick={function () { return handleSearch(selectedKeys, confirm); }} size="small" style={{
                width: 90,
            }}>
            Поиск
          </Button>
          <Button onClick={function () { return clearFilters && handleReset(clearFilters, selectedKeys, confirm); }} size="small" style={{
                width: 90,
            }}>
            Сброс
          </Button>
        </Space>
      </div>);
    },
    filterIcon: <SearchOutlined />,
    onFilter: function (value, record) {
        var idx = Object.values(roles).findIndex(function (el) { return el.toLowerCase().includes(String(value).toLowerCase()); });
        if (idx !== -1) {
            value = Object.keys(roles)[idx];
        }
        return (
        // TODO Исправить типизацию
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (Array.isArray(dataIndex) ? record[dataIndex[0]][dataIndex[1]] : record[dataIndex])
            .toString()
            .toLowerCase()
            .includes(String(value).toLowerCase()));
    },
}); };
export default getColumnSearchProps;
