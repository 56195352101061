var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, notification, Select, } from 'antd';
import { useMutation } from 'react-query';
import preorder from '@/utils/rest/preorder';
function CopySlot(_a) {
    var officeId = _a.officeId, queues = _a.queues, dayId = _a.dayId, refetch = _a.refetch, closeDrawer = _a.closeDrawer;
    var _b = useState([]), selectedQueues = _b[0], setSelectedQueues = _b[1];
    var _c = useState(false), forAll = _c[0], setForAll = _c[1];
    var _d = useState(), dateInterval = _d[0], setDateInterval = _d[1];
    var _e = useState(false), overwrite = _e[0], setOverwrite = _e[1];
    var mutate = useMutation({
        mutationFn: function (variables) { return preorder.copySlot(variables.officeId, variables.data); },
        onSuccess: function () {
            notification.success({
                message: 'Слоты успешно скопированы',
            });
            refetch();
            closeDrawer();
        },
        onError: function () {
            notification.error({
                message: 'Не удалось скопировать слоты',
            });
        },
    }).mutate;
    var selectQueues = function (values) {
        setSelectedQueues(values);
    };
    var selectDate = function (values) {
        if (values && values[0] && values[1]) {
            var dates = { start: values[0].format('YYYY.MM.DD'), end: values[1].format('YYYY.MM.DD') };
            setDateInterval(dates);
        }
    };
    var copySlots = function () {
        var qArr = __spreadArray([], selectedQueues, true);
        if (forAll) {
            queues === null || queues === void 0 ? void 0 : queues.forEach(function (queue) {
                if (queue.qId !== 0) {
                    qArr.push(Number(queue.qId));
                }
            });
        }
        var data = {
            dayId: dayId,
            begin: dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.start,
            end: dateInterval === null || dateInterval === void 0 ? void 0 : dateInterval.end,
            queues: qArr,
            replace: overwrite,
        };
        mutate({ officeId: officeId, data: data });
    };
    return (<Form style={{ width: '600px' }} layout="vertical">
      <Form.Item style={{ width: '100%' }} label="Список услуг для копирования слотов">
        <Select disabled={forAll} onChange={selectQueues} mode="multiple">
          {queues && queues.map(function (queue) { return (<Select.Option key={queue.qId} value={Number(queue.qId)}>
              {queue.qFullName}
            </Select.Option>); })}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox checked={forAll} onChange={function (e) { return setForAll(e.target.checked); }}>
          Скопировать все слоты по всем услугам
        </Checkbox>
      </Form.Item>
      <Form.Item style={{ width: '100%' }} label="Интервал дат для копирования">
        <DatePicker.RangePicker onChange={selectDate} style={{ width: '100%' }} format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item>
        <Checkbox checked={overwrite} onChange={function (e) { return setOverwrite(e.target.checked); }}>
          Перезаписывать слоты
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button onClick={copySlots} type="primary" disabled={!dateInterval || (selectedQueues.length === 0 && !forAll)}>
          Копировать слоты
        </Button>
      </Form.Item>
    </Form>);
}
export default CopySlot;
