import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Space, Input, Popover, Radio, } from 'antd';
import OfficeCard from '@/pages/Map/officeCard';
var sortTypeOptions = [
    { label: 'По возрастанию', value: 'DESC' },
    { label: 'По убыванию', value: 'ASC' },
];
var sortValueOptions = [
    { label: 'По названию офиса', value: 'office_name' },
    { label: 'По времени ожидания', value: 'avg_wait_time' },
    { label: 'По времени обслуживания', value: 'avg_proc_time' },
];
function OfficeList(_a) {
    var offices = _a.offices, checkOffice = _a.checkOffice, timePoints = _a.timePoints, fullscreen = _a.fullscreen, sort = _a.sort, setSortValue = _a.setSortValue, setSortType = _a.setSortType, fieldName = _a.fieldName;
    var _b = useState(''), filterValue = _b[0], setFilterValue = _b[1];
    var sortValue = sort[0], sortType = sort[1];
    var handleSearch = function (e) {
        var value = e.target.value;
        setFilterValue(value);
    };
    var onSortTypeChange = function (_a) {
        var value = _a.target.value;
        setSortType(value);
    };
    var onSortValueChange = function (_a) {
        var value = _a.target.value;
        setSortValue(value);
    };
    return (<>
      <div className="office__list__search">
        <Input className="search__input" placeholder="Введите название отделения для поиска" onChange={handleSearch}/>
        <Popover title="Сортировка офисов" trigger="click" content={(<div className="office__list__sort">
              <Radio.Group options={sortTypeOptions} optionType="button" onChange={onSortTypeChange} value={sortType}/>
              <Radio.Group value={sortValue} onChange={onSortValueChange}>
                <Space direction="vertical">
                  {sortValueOptions.map(function (option) { return (<Radio key={option.value} value={option.value}>{option.label}</Radio>); })}
                </Space>
              </Radio.Group>
            </div>)}>
          <SettingOutlined style={{ fontSize: '24px' }}/>
        </Popover>
      </div>
      <div className={fullscreen ? 'office__list__fullscreen' : 'office__list'}>
        <Space style={{ padding: '0 5px', width: '100%' }} direction="vertical">
          {offices.filter(function (office) { return office.office_name.toLowerCase().includes(filterValue.toLowerCase()); }).sort(function (el) { return (el.sync_status !== 1 ? 1 : -1); }).map(function (office) { return office.sync_status !== 2 && (<OfficeCard timePoints={timePoints} checkOffice={checkOffice} key={office.office_id} office={office} fieldName={fieldName}/>); })}
        </Space>
      </div>
    </>);
}
export default OfficeList;
