import React from 'react';
import { Card, Statistic, Table } from 'antd';
import OfficeInfo from '@/pages/Reports/officeReport/officeInfo';
import { getColumns } from '@/pages/Reports/officeReport/tableColumns';
import { AppStore } from '@/store';
import secToTime from '@/utils/rest/secToTime';
function OfficesList(_a) {
    var dates = _a.dates, offices = _a.offices;
    var showDays = AppStore.useState(function (s) { return s.showDays; });
    return (<>
      {offices.map(function (office) { return (<Card key={office.id} title={office.name}>
          <div className="office__card__statistic">
            <Statistic title="Всего клиентов" value={office['statistic.ticket_count']}/>
            <Statistic title="Средняя длительность обслуживания" value={secToTime(Number(office['statistic.avg_proc']), showDays)}/>
            <Statistic title="Общая длительность обслуживания" value={secToTime(Number(office['statistic.total_proc']), showDays)}/>
            <Statistic title="Средняя длительность ожидания" value={secToTime(Number(office['statistic.avg_wait']), showDays)}/>
            <Statistic title="Общая длительность ожидания" value={secToTime(Number(office['statistic.total_wait']), showDays)}/>
            <Statistic title="Всего очередей/услуг" value={office['statistic.queue_count']}/>
            <Statistic title="CSI" value={Number(office['statistic.avg_mark']).toFixed(2)}/>
            <Statistic title="Отлично" value={office['statistic.mark5']}/>
            <Statistic title="Хорошо" value={office['statistic.mark4']}/>
            <Statistic title="Нормально" value={office['statistic.mark3']}/>
            <Statistic title="Плохо" value={office['statistic.mark2']}/>
            <Statistic title="Оч. плохо" value={office['statistic.mark1']}/>
          </div>
          <Table rowKey="queue_id" dataSource={office.queues} columns={getColumns(showDays)} expandable={{
                expandedRowRender: function (record) { return (<OfficeInfo dates={dates} queue={record} officeId={office.id}/>); },
            }}/>
        </Card>); })}
    </>);
}
export default React.memo(OfficesList);
