var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
var getColumns = function (officeList, handleEdit, handleDelete) { return [
    {
        dataIndex: 'email',
        title: 'Email',
        width: 300,
    },
    {
        dataIndex: 'description',
        title: 'Описание',
        width: 400,
    },
    {
        dataIndex: 'offices',
        title: 'Подключенные офисы',
        render: function (_, row) {
            var connectedOffices = JSON.parse(row.offices);
            return (<div style={{
                    display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap',
                }}>
          {connectedOffices.length === 0 ? 'Все офисы' : connectedOffices.map(function (id) {
                    var office = officeList === null || officeList === void 0 ? void 0 : officeList.find(function (el) { return el.id === Number(id); });
                    if (office) {
                        return <p className="office__name">{office.name}</p>;
                    }
                    return null;
                })}
        </div>);
        },
    },
    {
        key: 'settings',
        width: 80,
        align: 'center',
        render: function (_, row) {
            var currentRow = __assign({}, row);
            currentRow.offices = JSON.parse(row.offices);
            return (<div className="table__button__box">
          <button className="table__button" onClick={function () { handleEdit(currentRow); }} type="submit">
            <EditTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <button className="table__button" onClick={function () { return handleDelete(currentRow); }} type="submit">
            <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
          </button>
        </div>);
        },
    },
]; };
export default getColumns;
