var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Button, Drawer, notification, Table, } from 'antd';
import { useQuery, useMutation } from 'react-query';
import getColumns from '@/pages/Users/tableColumns';
import UserEdit from '@/pages/Users/userEdit';
import { UserStore } from '@/store';
import { isAdmin } from '@/utils/checkRole';
import user from '@/utils/rest/user';
function Users() {
    var _a = useState(), currentUser = _a[0], setCurrentUser = _a[1];
    var _b = useState(false), openDrawer = _b[0], setOpenDrawer = _b[1];
    var userRole = UserStore.useState(function (s) { return s.role; });
    var cancelEdit = function () {
        setCurrentUser(undefined);
        setOpenDrawer(false);
    };
    var _c = useQuery('users', user.getAll), isLoading = _c.isLoading, data = _c.data, refetch = _c.refetch;
    var updateUser = useMutation({
        mutationFn: function (variables) { return user.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Данные пользователя успешно обновлены',
            });
            cancelEdit();
            refetch();
        },
        onError: function (err) {
            notification.error({
                message: 'Не удалось обновить данные пользователя',
            });
            console.log(err);
        },
    }).mutate;
    var createUser = useMutation({
        mutationFn: function (variables) { return user.create(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Пользователь успешно добавлен',
            });
            cancelEdit();
            refetch();
        },
        onError: function (err) {
            notification.error({
                message: 'Не удалось добавить пользователя',
            });
            console.log(err);
        },
    }).mutate;
    var deleteUser = useMutation({
        mutationFn: function (id) { return user.delete(id); },
        onSuccess: function () {
            notification.success({
                message: 'Пользователь удалён',
            });
            refetch();
        },
        onError: function (err) {
            notification.error({
                message: 'Не удалось удалить пользователя',
            });
            console.log(err);
        },
    }).mutate;
    var handleEdit = function (userData) {
        setCurrentUser(userData);
        setOpenDrawer(true);
    };
    var handleDelete = function (id) {
        deleteUser(id);
    };
    var handleUser = function (formData) {
        if (currentUser) {
            updateUser(__assign(__assign({}, currentUser), formData));
        }
        else {
            createUser(__assign({}, formData));
        }
    };
    return (<>
      <h2 className="page__header">Пользователи</h2>
      <div className="page__content">
        {isAdmin(userRole) && <Button onClick={function () { return setOpenDrawer(true); }} className="add__button" type="primary">Добавить</Button>}
        <Drawer destroyOnClose onClose={cancelEdit} width={600} open={openDrawer}>
          <UserEdit handleUser={handleUser} cancel={cancelEdit} user={currentUser}/>
        </Drawer>
        <Table rowKey="id" dataSource={data} columns={getColumns(handleEdit, handleDelete, userRole)} loading={isLoading} sticky/>
      </div>
    </>);
}
export default Users;
