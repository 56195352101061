import React, { useState } from 'react';
import { SaveTwoTone } from '@ant-design/icons';
import { Divider, Input, Switch, TimePicker, ColorPicker, } from 'antd';
import dayjs from 'dayjs';
import { timeFormat } from '@/utils/constants';
function EditableCell(_a) {
    var row = _a.row, type = _a.type, onChange = _a.onChange;
    var value = row.value, param = row.param;
    var _b = useState(value), inputValue = _b[0], setInputValue = _b[1];
    var _c = useState(false), isChanged = _c[0], setIsChanged = _c[1];
    var _d = useState(undefined), currentKey = _d[0], setCurrentKey = _d[1];
    var changeTime = function (time) {
        if (time) {
            if (param === 'httpSessionExpireAfterSecs' && Number(value) > 86400) {
                var newTime = time.hour() * 3600
                    + time.minute() * 60
                    + time.second()
                    + Number(value);
                onChange(param, newTime);
            }
            else {
                var newTime = time.hour() * 3600
                    + time.minute() * 60
                    + time.second();
                onChange(param, newTime);
            }
        }
    };
    var changeInterval = function (time, paramType) {
        if (time) {
            var interval = JSON.parse(value);
            interval[paramType] = time.hour() * 3600
                + time.minute() * 60
                + time.second();
            onChange(param, JSON.stringify(interval));
        }
    };
    var changeColor = function (color, colorType) {
        if (colorType) {
            var interval = JSON.parse(value);
            interval.colors[colorType] = "#".concat(color.toHex());
            onChange(param, JSON.stringify(interval));
        }
        else {
            onChange(param, JSON.stringify({ color: "#".concat(color.toHex()) }));
        }
    };
    var changeDay = function (days) {
        var newValue = days * 86400;
        onChange(param, newValue);
    };
    var handleCheck = function (checkValue) {
        onChange(param, checkValue ? '1' : '0');
    };
    var handleInput = function (e) {
        var newInputValue = e.target.value;
        setInputValue(newInputValue);
        setIsChanged(true);
        setCurrentKey(param);
    };
    var saveParam = function () {
        onChange(param, inputValue);
        setIsChanged(false);
        setCurrentKey(undefined);
    };
    var renderInput = function () {
        switch (type) {
            case 'color':
                return (<div>
            <ColorPicker size="small" defaultValue={JSON.parse(value).color} onChangeComplete={function (color) { return changeColor(color, undefined); }}/>
          </div>);
            case 'interval':
                return (<>
            <div>
              <p>Меньше</p>
              <div className="settings__value">
                <TimePicker onChange={function (time) { return changeInterval(time, 'min'); }} defaultValue={dayjs.utc(JSON.parse(value).min * 1000)}/>
                <ColorPicker onChangeComplete={function (color) { return changeColor(color, 'min'); }} size="small" defaultValue={JSON.parse(value).colors.min}/>
              </div>
            </div>
            <Divider />
            <div>
              <p>В промежутке</p>
              <div className="settings__value">
                <p>
                  От
                  {' '}
                  {dayjs.utc(JSON.parse(value).min * 1000).format(timeFormat)}
                  {' '}
                  до
                  {' '}
                  {dayjs.utc(JSON.parse(value).max * 1000).format(timeFormat)}
                </p>
                <ColorPicker size="small" defaultValue={JSON.parse(value).colors.mid} onChangeComplete={function (color) { return changeColor(color, 'mid'); }}/>
              </div>
            </div>
            <Divider />
            <div>
              <p>Более</p>
              <div className="settings__value">
                <TimePicker onChange={function (time) { return changeInterval(time, 'max'); }} defaultValue={dayjs.utc(JSON.parse(value).max * 1000)}/>
                <ColorPicker size="small" defaultValue={JSON.parse(value).colors.max} onChangeComplete={function (color) { return changeColor(color, 'max'); }}/>
              </div>
            </div>
          </>);
            case 'time':
                return (<div className="settings__value">
            <TimePicker onChange={changeTime} defaultValue={dayjs.utc(Number(value) * 1000)}/>
            {param === 'httpSessionExpireAfterSecs'
                        && (<>
                <p>Дней</p>
                <Input min={0} style={{ width: '100px' }} type="number" onChange={function (e) { return changeDay(Number(e.target.value)); }} defaultValue={Math.floor(Number(value) / 86400)}/>
              </>)}
          </div>);
            case 'boolean':
                return (<Switch key="test" defaultChecked={Boolean(Number(value))} onChange={handleCheck}/>);
            default:
                return (<div className="settings__input">
            <Input defaultValue={inputValue} onChange={handleInput} disabled={param !== currentKey && isChanged}/>
            {isChanged && <SaveTwoTone style={{ fontSize: '30px' }} onClick={saveParam}/>}
          </div>);
        }
    };
    return renderInput();
}
export default EditableCell;
