import React, { useState } from 'react';
import { Button, Drawer, notification, Table, } from 'antd';
import { useMutation, useQuery } from 'react-query';
import GroupEdit from '@/pages/Groups/groupEdit';
import getColumns from '@/pages/Groups/tableColumns';
import { UserStore } from '@/store';
import { isAdmin } from '@/utils/checkRole';
import groups from '@/utils/rest/groups';
import offices from '@/utils/rest/offices';
function Groups() {
    var _a = useState(false), isDrawerOpen = _a[0], setIsDrawerOpen = _a[1];
    var _b = useState(), currentRow = _b[0], setCurrentRow = _b[1];
    var userRole = UserStore.useState(function (s) { return s.role; });
    var officeList = useQuery('offices', offices.getAll).data;
    var _c = useQuery('groups', groups.getAll), data = _c.data, isLoading = _c.isLoading, refetch = _c.refetch;
    var toggleDrawer = function () {
        setIsDrawerOpen(function (prevState) { return !prevState; });
    };
    var creatGroup = useMutation({
        mutationFn: function (variables) { return groups.create(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Группировка успешно добавлена',
            });
            refetch();
            toggleDrawer();
        },
        onError: function (err) {
            notification.error({
                message: 'Не удалось добавить группировку',
            });
            console.log(err);
        },
    }).mutate;
    var deleteGroup = useMutation({
        mutationFn: function (id) { return groups.delete(id); },
        onSuccess: function () {
            notification.success({
                message: 'Группировка успешно удалена',
            });
            refetch();
        },
        onError: function (err) {
            notification.error({
                message: 'Не удалось удалить группировку',
            });
            console.log(err);
        },
    }).mutate;
    var updateMutate = useMutation({
        mutationFn: function (variables) { return groups.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Группировка успешно обновлена',
            });
            refetch();
            setCurrentRow(undefined);
            toggleDrawer();
        },
    }).mutate;
    var onFinish = function (values) {
        if (currentRow) {
            values.id = currentRow.id;
            updateMutate(values);
        }
        else {
            creatGroup(values);
        }
    };
    var handleGroup = function (row) {
        setCurrentRow(row);
        toggleDrawer();
    };
    return (<>
      <h2 className="page__header">Группировки</h2>
      <div className="page__content">
        {isAdmin(userRole) && <Button onClick={toggleDrawer} className="add__button" type="primary">Добавить</Button>}
        <Drawer open={isDrawerOpen} onClose={toggleDrawer} destroyOnClose width={800}>
          <GroupEdit currentGroup={currentRow} toggleDrawer={toggleDrawer} officeList={officeList || []} onFinish={onFinish}/>
        </Drawer>
        <Table rowKey="id" dataSource={data} columns={getColumns(deleteGroup, handleGroup, userRole)} loading={isLoading}/>
      </div>
    </>);
}
export default Groups;
