import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { dateTimeFormat, timeFormat } from '@/utils/constants';
var statuses = {
    3: 'Закрыт',
    4: 'Закрыт по неявке',
    9: 'Удалён системой',
};
var getColumns = function () { return [
    {
        title: 'ID талона',
        dataIndex: 'ticket_id',
        align: 'center',
        fixed: 'left',
        width: 100,
        sorter: true,
    },
    {
        title: 'Номер талона',
        dataIndex: 'ticket_number',
        align: 'center',
        fixed: 'left',
        width: 100,
        sorter: true,
    },
    {
        title: 'Отделение',
        dataIndex: 'office_name',
        width: 200,
        sorter: true,
    },
    {
        title: 'Очередь',
        dataIndex: 'queue_name',
        width: 200,
        sorter: true,
    },
    {
        title: 'Рабочее место',
        dataIndex: 'workplace_name',
        width: 200,
        sorter: true,
    },
    {
        title: 'Оператор',
        dataIndex: 'operator_fio',
        width: 200,
        sorter: true,
    },
    {
        title: 'Приоритет',
        dataIndex: 'prior',
        render: function (_, row) { return (row.prior < 100 ? <p>Обычный</p> : <p>Высокий</p>); },
    },
    {
        title: 'Дата постановки в очередь',
        dataIndex: 'create_date',
        render: function (_, row) { return moment(row.create_date).format(dateTimeFormat); },
        sorter: true,
    },
    {
        title: 'Дата вызова',
        dataIndex: 'call_date',
        render: function (value) { return dayjs(value).format(dateTimeFormat); },
        sorter: true,
    },
    {
        title: 'Дата окончания обслуживания',
        dataIndex: 'close_date',
        render: function (_, row) { return moment(row.close_date).format(dateTimeFormat); },
        sorter: true,
    },
    {
        title: 'Время ожидания',
        dataIndex: 'wait_time',
        render: function (_, row) { return moment.utc(Number(row.wait_time) * 1000).format(timeFormat); },
        sorter: true,
    },
    {
        title: 'Время обслуживания',
        dataIndex: 'proc_time',
        render: function (_, row) { return moment.utc(Number(row.proc_time) * 1000).format(timeFormat); },
        sorter: true,
    },
    {
        title: 'Статус',
        dataIndex: 'close_status',
        width: 200,
        render: function (_, row) { return statuses[Number(row.close_status)]; },
    },
    {
        title: 'Аудиозапись',
        dataIndex: 'audio',
        width: 380,
        align: 'center',
        render: function (value, row) {
            if (value) {
                return <audio controls src={value}/>;
            }
            return dayjs(row.close_date).isBefore(dayjs().subtract(1, 'day')) ? 'Нет записи' : 'Проверка наличия записи';
        },
    },
    {
        title: 'Оценка',
        dataIndex: 'mark',
        width: 100,
    },
]; };
export default getColumns;
