import React from 'react';
import GroupReport from '@/pages/Reports/groupReport';
import HourlyReport from '@/pages/Reports/hourlyReport';
import OfficeReport from '@/pages/Reports/officeReport';
import OfficeStatistic from '@/pages/Reports/officeStatistic';
import OperatorReport from '@/pages/Reports/operatorReport';
import QuestionReport from '@/pages/Reports/questionReport';
import QueueReport from '@/pages/Reports/queueReport';
import TicketReport from '@/pages/Reports/ticketReport';
var reportItems = [
    {
        label: 'Отчёт по талонам',
        key: 'ticketReport',
        children: <TicketReport />,
    },
    {
        label: 'Отчёт по операторам',
        key: 'operatorReport',
        children: <OperatorReport />,
    },
    {
        label: 'Отчёт по услугам',
        key: 'queueReport',
        children: <QueueReport />,
    },
    {
        label: 'Отчёт по отделениям',
        key: 'officeReport',
        children: <OfficeReport />,
    },
    {
        label: 'Почасовой отчёт',
        key: 'hourlyReport',
        children: <HourlyReport />,
    },
    {
        label: 'Отчёт по группировкам',
        key: 'groupReport',
        children: <GroupReport />,
    },
    {
        label: 'Отчёт по оценке качества',
        key: 'questionReport',
        children: <QuestionReport />,
    },
    {
        label: 'Отчёт по статистике офисов',
        key: 'statisticReport',
        children: <OfficeStatistic />,
    },
];
export default reportItems;
