var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, DatePicker, notification, Skeleton, Table, } from 'antd';
import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import { dateTimeFormat, seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function QuestionReport() {
    var _a = useState([
        dayjs().startOf('month'),
        dayjs().endOf('day'),
    ]), dates = _a[0], setDates = _a[1];
    var _b = useState([
        {
            dataIndex: 'office_id',
            title: 'ID офиса',
            width: 80,
            align: 'center',
            fixed: 'left',
        },
        {
            dataIndex: 'office_name',
            title: 'Название офиса',
            width: 280,
            fixed: 'left',
        },
    ]), columns = _b[0], setColumns = _b[1];
    var _c = useQuery('questionReport', function () { return report.getReport({
        startDate: dates[0].format(seqTimeFormat),
        endDate: dates[1].format(seqTimeFormat),
        reportType: 'questionReport',
    }); }), isFetching = _c.isFetching, isLoading = _c.isLoading, reportData = _c.data, refetch = _c.refetch;
    var _d = useMutation({
        mutationFn: function (variables) { return report.export(variables.startDate, variables.endDate, variables.reportType); },
        onSuccess: function (exportRes) {
            if (exportRes.status === 'ok') {
                notification.success({
                    message: 'Отчёт подготавливается к скачиванию, скачать отчёт можно в разделе Архив',
                });
            }
            else {
                notification.error({
                    message: exportRes.error,
                });
            }
        },
    }), exportReport = _d.mutate, isExportLoading = _d.isLoading;
    var handleChange = function (values, strDates) {
        var newStartDate = values ? dayjs(strDates[0], 'DD.MM.YYYY HH:mm') : dayjs().startOf('month');
        var newEndDate = values ? dayjs(strDates[1], 'DD.MM.YYYY HH:mm') : dayjs().endOf('day');
        setDates([newStartDate, newEndDate]);
    };
    var handleExport = function () {
        exportReport({
            startDate: dates[0].format(seqTimeFormat),
            endDate: dates[1].format(seqTimeFormat),
            reportType: 'questionReport',
        });
    };
    useEffect(function () {
        var tableColumns = __spreadArray([], columns, true);
        reportData === null || reportData === void 0 ? void 0 : reportData.items.forEach(function (item) {
            item.questions.forEach(function (el) {
                var idx = tableColumns.findIndex(function (column) { return column.title === el.question; });
                if (idx === -1) {
                    var newColumn_1 = {
                        title: el.question,
                        children: [],
                    };
                    Object.keys(el.answers).forEach(function (answer) {
                        newColumn_1.children.push({
                            title: answer,
                            dataIndex: answer,
                            align: 'center',
                            render: function (_, record) {
                                var question = record.questions.find(function (recordEl) { return recordEl.question === el.question; });
                                return question === null || question === void 0 ? void 0 : question.answers[answer];
                            },
                        });
                    });
                    if (!Number.isNaN(Number(Object.keys(el.answers)[0]))) {
                        newColumn_1.children.push({
                            title: 'Средний\n балл',
                            align: 'center',
                            width: 100,
                            render: function (_, record) {
                                var question = record.questions.find(function (recordEl) { return recordEl.question === el.question; });
                                var sum = 0;
                                var count = 0;
                                if (question) {
                                    Object.keys(question.answers).forEach(function (answer) {
                                        sum += (Number(answer) * Number(question.answers[answer]));
                                        count += Number(question.answers[answer]);
                                    });
                                }
                                return count === 0 ? 0 : Number(sum / count).toFixed(2);
                            },
                        });
                    }
                    tableColumns.push(newColumn_1);
                }
            });
        });
        setColumns(tableColumns);
    }, [reportData]);
    return (<>
      <div className="filter__box">
        <DatePicker.RangePicker format={dateTimeFormat} onChange={handleChange} defaultValue={dates} showTime={{
            defaultValue: [
                dayjs().startOf('day'),
                dayjs().endOf('day'),
            ],
        }} presets={[
            {
                label: 'За предыдущий месяц',
                value: [
                    dayjs().add(-1, 'M').startOf('month'),
                    dayjs().add(-1, 'M').endOf('month'),
                ],
            },
            {
                label: 'За год',
                value: [
                    dayjs().startOf('year'),
                    dayjs().endOf('day'),
                ],
            },
        ]} style={{ width: '400px' }}/>
        <div className="filter__button__box">
          <Button className="filter__button" type="primary" onClick={function () { return refetch(); }}>Применить</Button>
          <Button className="filter__button" type="primary" onClick={handleExport} loading={isExportLoading}>Экспортировать</Button>
        </div>
      </div>
      {isLoading || isFetching
            ? <Skeleton />
            : (<Table rowKey="office_id" dataSource={reportData === null || reportData === void 0 ? void 0 : reportData.items} loading={isLoading || isFetching} columns={columns} scroll={{ x: columns.length * 400 }} bordered/>)}
    </>);
}
export default QuestionReport;
