import React, { useState } from 'react';
import { Button, Modal, notification, Table, Tabs, } from 'antd';
import { useMutation, useQuery } from 'react-query';
import Migration from '@/pages/Settings/Migration';
import getColumns from '@/pages/Settings/tableColumns';
import { UserStore } from '@/store';
import settings from '@/utils/rest/settings';
function Settings() {
    var _a = useState(false), openModal = _a[0], setOpenModal = _a[1];
    var _b = useQuery('settings', settings.getAll), isLoading = _b.isLoading, data = _b.data, refetch = _b.refetch;
    var updateSettings = useMutation({
        mutationFn: function (variables) { return settings.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Параметр успешно изменён',
            });
            refetch();
        },
        onError: function (err) {
            notification.error({
                message: err.response.data.message,
            });
        },
    }).mutate;
    var userRole = UserStore.useState(function (s) { return s.role; });
    var onSettingsChange = function (key, value) {
        var _a;
        updateSettings((_a = {}, _a[key] = value, _a));
    };
    var toggleModal = function () {
        setOpenModal(function (prevState) { return !prevState; });
    };
    var settingsItems = [
        {
            key: 'item-1',
            label: 'Настройки сервера',
            children: (<Table rowKey="id" loading={isLoading} columns={getColumns(onSettingsChange)} dataSource={data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.group_name === 'system'; })}/>),
        },
        {
            key: 'item-2',
            label: 'Настройки почтовой рассылки',
            children: (<Table rowKey="id" loading={isLoading} columns={getColumns(onSettingsChange)} dataSource={data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.group_name === 'email'; })}/>),
        },
    ];
    return (<>
      <h2 className="page__header">Параметры</h2>
      {userRole === 'root' && (<>
          <Button onClick={toggleModal}>Миграция настроек</Button>
          <Modal footer={null} onCancel={toggleModal} open={openModal} centered width={1200}>
            <Migration refetch={refetch} cancel={toggleModal}/>
          </Modal>
        </>)}
      <Tabs items={settingsItems}/>
    </>);
}
export default Settings;
