import React from 'react';
import { Button, Form, Input, notification, } from 'antd';
import './login.css';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UserStore } from '@/store';
import user from '@/utils/rest/user';
function Login() {
    var navigate = useNavigate();
    var mutation = useMutation({
        mutationFn: function (authData) { return user.auth(authData.login, authData.password); },
        onSuccess: function (userData) {
            if (userData.role === 'checklister') {
                window.location.replace("https://checklist.a-7.tech?token=".concat(userData.token));
                Cookies.remove('connect.sid');
            }
            else {
                Cookies.set('token', userData.token, { sameSite: 'none', secure: true });
                UserStore.update(function (s) {
                    s.fullname = userData.fullname;
                    s.role = userData.role;
                });
                navigate('/monitoring');
            }
        },
        onError: function (err) {
            notification.error({
                message: err.response.data.message,
            });
        },
    });
    useQuery('checkAuth', user.checkAuth, {
        onSuccess: function (userData) {
            if (userData.role === 'checklister') {
                window.location.replace("https://checklist.a-7.tech?token=".concat(userData.token));
                Cookies.remove('connect.sid');
            }
            else {
                Cookies.set('token', userData.token, { sameSite: 'none', secure: true });
                UserStore.update(function (s) {
                    s.fullname = userData.fullname;
                    s.role = userData.role;
                });
                navigate('/monitoring');
            }
        },
        retry: false,
    });
    var onFinish = function (values) {
        mutation.mutate({ login: values.login, password: values.password });
    };
    return (<div className="login__box">
      <h1 className="login__header">Единый сервер мониторинга</h1>
      <Form name="login" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ width: 600 }} onFinish={onFinish}>
        <Form.Item name="login" label="Имя пользователя" rules={[{ required: true, message: 'Введите имя пользователя' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="password" label="Пароль" rules={[{ required: true, message: 'Введите пароль' }]}>
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button size="middle" type="primary" htmlType="submit" style={{ width: '200px' }}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>);
}
export default Login;
