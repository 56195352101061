import React from 'react';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import widgets from '@/utils/rest/widgets';
function Widget() {
    var id = useParams().id;
    var _a = useQuery(['widget', id], widgets.getOne, {
        enabled: !!id,
    }), widget = _a.data, isLoading = _a.isLoading;
    var token = Cookies.get('token');
    return isLoading ? <h1>Loading...</h1> : (<iframe className="widget" src={"".concat(widget.frame_url, "?token=").concat(token)} title={widget.name} width="100%" height="100%"/>);
}
export default Widget;
