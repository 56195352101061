var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Switch } from 'antd';
import dayjs from 'dayjs';
import OfficeEditCell from '@/components/officeEditCell';
import { isAdmin } from '@/utils/checkRole';
import { timeDateFormat } from '@/utils/constants';
import getColumnSearchProps from '@/utils/tableSearch';
export var getColumns = function (onChange, handleEdit, handleDelete, onSave, role, columnName) {
    var columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Дата добавления',
            dataIndex: 'createdAt',
            render: function (value) { return dayjs(value).format(timeDateFormat); },
        },
        {
            title: 'Название компании',
            key: 'company',
            render: function (_, row) { return row.Company.name; },
        },
        __assign({ title: 'Название офиса', dataIndex: 'name', sorter: function (a, b) { return ([
                a.name.toLowerCase(),
                b.name.toLowerCase(),
            ].sort()[0] === a.name.toLowerCase() ? -1 : 1); } }, getColumnSearchProps('name', 'по названию офиса')),
        {
            title: 'Адрес офиса',
            dataIndex: 'situation',
        },
        {
            title: 'Адрес подключения',
            dataIndex: 'url',
            render: function (value) { return (value || 'Нет электронной очереди'); },
        },
        {
            title: 'Версия',
            children: [
                {
                    title: 'Сервер',
                    dataIndex: 'baqservversion',
                },
                {
                    title: 'БД',
                    dataIndex: 'baqdbversion',
                },
            ],
        },
        {
            title: columnName,
            width: 200,
            render: function (_, row) { return <OfficeEditCell row={row} onSave={onSave}/>; },
        },
        {
            title: 'Синхронизация',
            dataIndex: 'sync',
            align: 'center',
            render: function (_, row) { return (<Switch disabled={!row.url} defaultChecked={Boolean(row.sync)} onChange={function (sync) { return onChange(sync, row.id); }}/>); },
        },
    ];
    if (isAdmin(role)) {
        columns.push({
            title: 'Действия',
            dataIndex: 'actions',
            render: function (_, row) { return (<div className="table__button__box">
          <button className="table__button" onClick={function () { handleEdit(row); }} type="submit">
            <EditTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <button className="table__button" onClick={function () { return handleDelete(row); }} type="submit">
            <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
          </button>
        </div>); },
        });
    }
    return columns;
};
export var getMigrationColumns = function () { return [
    {
        title: 'Название',
        dataIndex: 'name',
    },
    {
        title: 'Адрес',
        dataIndex: 'situation',
    },
]; };
