var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import moment from 'moment';
import { isAdmin } from '@/utils/checkRole';
import { dateTimeFormat } from '@/utils/constants';
import getColumnSearchProps from '@/utils/tableSearch';
var roles = {
    root: 'Главный администратор',
    admin: 'Администратор',
    user: 'Пользователь',
    reader: 'Читатель отчётов',
    checklister: 'Анкетировщик',
};
var getColumns = function (handleEdit, handleDelete, role) {
    var columns = [
        __assign({ title: 'Роль', dataIndex: 'role', render: function (value) { return roles[value]; }, sorter: function (a, b) { return ([
                roles[a.role].toLowerCase(),
                roles[b.role].toLowerCase(),
            ].sort()[0] === roles[a.role].toLowerCase() ? -1 : 1); } }, getColumnSearchProps('role', 'по роли')),
        __assign({ title: 'Имя', dataIndex: 'fullname' }, getColumnSearchProps('fullname', 'По имени')),
        {
            title: 'Адрес электронной почты',
            dataIndex: 'email',
        },
        {
            title: 'Последнее обновление',
            dataIndex: 'updatedAt',
            render: function (value) { return moment(value).format(dateTimeFormat); },
        },
    ];
    if (isAdmin(role)) {
        columns.push({
            title: 'Действия',
            dataIndex: 'actions',
            width: 140,
            render: function (_, row) { return (<div className="table__button__box">
          <button className="table__button" onClick={function () { return handleEdit(row); }} type="submit">
            <EditTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <Popconfirm title="Удалить пользователя?" onConfirm={function () { return handleDelete(Number(row.id)); }}>
            <button className="table__button" type="submit">
              <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
            </button>
          </Popconfirm>
        </div>); },
        });
    }
    return columns;
};
export default getColumns;
