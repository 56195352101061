import React, { useEffect } from 'react';
import { Skeleton, Statistic, Table, } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/components/Filters';
import OperatorInfo from '@/pages/Reports/operatorReport/operatorInfo';
import { getColumns } from '@/pages/Reports/operatorReport/tableColumns';
import { AppStore, FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
import secToTime from '@/utils/rest/secToTime';
function OperatorReport() {
    var showDays = AppStore.useState(function (s) { return s.showDays; });
    var filters = FilterStore.useState();
    var _a = useQuery('operators', function () { return report.getReport({
        startDate: filters.dates && (filters === null || filters === void 0 ? void 0 : filters.dates[0]) ? filters.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
        endDate: filters.dates && (filters === null || filters === void 0 ? void 0 : filters.dates[1]) ? filters.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
        reportType: 'operatorReport',
        filters: filters,
    }); }), isFetching = _a.isFetching, data = _a.data, refetch = _a.refetch;
    useEffect(function () {
        refetch();
    }, [filters]);
    return (<>
      <Filters refetch={refetch} filterItems={['offices', 'queues', 'operators']} reportType="operatorReport" totalCount={(data === null || data === void 0 ? void 0 : data.items.length) || 0} isExport/>
      <div className="report__statistic">
        {!isFetching ? (<>
            <Statistic title="Всего отделений" value={Number(data === null || data === void 0 ? void 0 : data.statistic.office_count)}/>
            <Statistic title="Всего операторов" value={Number(data === null || data === void 0 ? void 0 : data.statistic.operator_count)}/>
            <Statistic title="Всего клиентов" value={Number(data === null || data === void 0 ? void 0 : data.statistic.ticket_count)}/>
            <Statistic title="Общая длительность ожидания" value={secToTime(Number(data === null || data === void 0 ? void 0 : data.statistic.total_wait), showDays)}/>
            <Statistic title="Средняя длительность ожидания" value={secToTime(Number(data === null || data === void 0 ? void 0 : data.statistic.avg_wait), showDays)}/>
            <Statistic title="Общая длительность обслуживания" value={secToTime(Number(data === null || data === void 0 ? void 0 : data.statistic.total_proc), showDays)}/>
            <Statistic title="Средняя длительность обслуживания" value={secToTime(Number(data === null || data === void 0 ? void 0 : data.statistic.avg_proc), showDays)}/>
          </>) : (<>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
          </>)}
      </div>
      <Table rowKey="id" dataSource={data === null || data === void 0 ? void 0 : data.items} loading={isFetching} columns={getColumns()} expandable={{
            expandedRowRender: function (record) { return <OperatorInfo dates={filters.dates} operator={record}/>; },
        }}/>
    </>);
}
export default OperatorReport;
