var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Table, Button, Drawer, notification, Modal, } from 'antd';
import { useMutation, useQuery } from 'react-query';
import Migration from '@/pages/Offices/Migration';
import OfficeEdit from '@/pages/Offices/officeEdit';
import { getColumns } from '@/pages/Offices/tableColumns';
import { AppStore, UserStore } from '@/store';
import { isAdmin, isRoot } from '@/utils/checkRole';
import companies from '@/utils/rest/companies';
import handling from '@/utils/rest/handling';
import offices from '@/utils/rest/offices';
import YMap from '@/utils/yandexMap';
function Offices() {
    var _this = this;
    var params = AppStore.useState(function (s) { return s.params; });
    var columnNameParam = params === null || params === void 0 ? void 0 : params.find(function (el) { return el.param === 'nameOfAdditionalField'; });
    var _a = useState(), currentOffice = _a[0], setCurrentOffice = _a[1];
    var _b = useState(), newOffice = _b[0], setNewOffice = _b[1];
    var _c = useState(false), openDrawer = _c[0], setOpenDrawer = _c[1];
    var _d = useState(false), openModal = _d[0], setOpenModal = _d[1];
    var _e = useState(false), openMigrationModal = _e[0], setOpenMigrationModal = _e[1];
    var _f = useState(false), checkLoading = _f[0], setCheckLoading = _f[1];
    var _g = useQuery('offices', offices.getAll), isLoading = _g.isLoading, data = _g.data, refetch = _g.refetch;
    var companiesData = useQuery('companies', companies.getAll).data;
    var _h = useMutation({
        mutationFn: function (variables) { return offices.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Офис обновлён',
            });
            setCurrentOffice(undefined);
            setOpenDrawer(false);
            refetch();
        },
    }), updateOffice = _h.mutate, isUpdateLoading = _h.isLoading;
    var _j = useMutation({
        mutationFn: function (variables) { return offices.create(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Новый офис добавлен',
            });
            setOpenDrawer(false);
            setCurrentOffice(undefined);
            setNewOffice(undefined);
            refetch();
        },
    }), createOffice = _j.mutate, isCreateLoading = _j.isLoading;
    var _k = useMutation({
        mutationFn: function (id) { return offices.delete(id); },
        onSuccess: function () {
            notification.success({
                message: 'Офис удалён',
            });
            setOpenModal(false);
            setCurrentOffice(undefined);
            refetch();
        },
    }), deleteOffice = _k.mutate, isDeleteLoading = _k.isLoading;
    var createComment = useMutation({
        mutationFn: function (variables) { return handling.create(variables.comment, variables.id); },
        onSuccess: function () {
            refetch();
        },
        onError: function () {
            notification.error({
                message: 'Не удалось обновить данные',
            });
        },
    }).mutate;
    var userRole = UserStore.useState(function (s) { return s.role; });
    var toggleSync = function (sync, id) {
        var office = data === null || data === void 0 ? void 0 : data.find(function (el) { return el.id === id; });
        if (office) {
            office.sync = sync;
            updateOffice(office);
        }
    };
    var cancelEdit = function () {
        setCurrentOffice(undefined);
        setNewOffice(undefined);
        setOpenDrawer(false);
        setOpenModal(false);
        setOpenMigrationModal(false);
    };
    var handleEdit = function (office) {
        setCurrentOffice(office);
        setOpenDrawer(true);
    };
    var handleDelete = function (row) {
        setCurrentOffice(row);
        setOpenModal(true);
    };
    var officeUpdate = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var coords, cordsArr, officeData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, YMap.geocode(values.situation)];
                case 1:
                    coords = _a.sent();
                    cordsArr = coords.split(' ');
                    officeData = __assign(__assign(__assign({}, currentOffice), values), { gpsmark: "(".concat(cordsArr[0], ", ").concat(cordsArr[1], ")") });
                    if (currentOffice) {
                        updateOffice(officeData);
                    }
                    else {
                        createOffice(officeData);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var checkOffice = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var baseUrl, response, officeData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCheckLoading(true);
                    baseUrl = new URL(values.url).origin;
                    return [4 /*yield*/, offices.checkOffice(baseUrl)];
                case 1:
                    response = _a.sent();
                    if (response.status === 'ok') {
                        officeData = response.data;
                        setNewOffice({
                            name: officeData.officeName,
                            description: officeData.officeDiscription,
                            situation: officeData.officePhysicalAddress,
                            company_id: values.company_id,
                            url: baseUrl,
                        });
                        setCheckLoading(false);
                    }
                    else {
                        notification.error({
                            message: 'Не удалось получить информацию от офиса',
                        });
                        setCheckLoading(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var saveComment = function (row, value) {
        createComment({ id: row.id, comment: value });
    };
    var confirmDelete = function () {
        if (currentOffice) {
            deleteOffice(currentOffice.id);
        }
    };
    return (<>
      <h2 className="page__header">Офисы</h2>
      <div className="page__content">
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {isAdmin(userRole) && <Button onClick={function () { return setOpenDrawer(true); }} className="add__button" type="primary">Добавить</Button>}
          {isRoot(userRole)
            && <Button onClick={function () { return setOpenMigrationModal(true); }}>Миграция офисов</Button>}
        </div>
        {isRoot(userRole)
            && (<Modal open={openMigrationModal} onCancel={cancelEdit} centered footer={null} width={1200}>
            <Migration refetch={refetch} cancel={cancelEdit}/>
          </Modal>)}
        <Drawer destroyOnClose width={600} onClose={cancelEdit} open={openDrawer}>
          <OfficeEdit loading={isCreateLoading || isUpdateLoading || checkLoading} onFinish={officeUpdate} checkOffice={checkOffice} cancel={cancelEdit} office={currentOffice} newOffice={newOffice} companies={companiesData}/>
        </Drawer>
        <Modal confirmLoading={isDeleteLoading} onOk={confirmDelete} onCancel={cancelEdit} destroyOnClose title="Вы уверены, что хотите удалить офис?" open={openModal}>
          <p>{currentOffice === null || currentOffice === void 0 ? void 0 : currentOffice.name}</p>
          <p>{currentOffice === null || currentOffice === void 0 ? void 0 : currentOffice.situation}</p>
        </Modal>
        <Table rowKey="id" dataSource={data} loading={isLoading} columns={getColumns(toggleSync, handleEdit, handleDelete, saveComment, userRole, columnNameParam === null || columnNameParam === void 0 ? void 0 : columnNameParam.value)}/>
      </div>
    </>);
}
export default Offices;
