import React from 'react';
import { Button, Form, Input, notification, } from 'antd';
import { useMutation } from 'react-query';
import settings from '@/utils/rest/settings';
function Migration(_a) {
    var cancel = _a.cancel, refetch = _a.refetch;
    var _b = useMutation({
        mutationFn: function (variables) { return settings.getESMParams(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Настройки сервера успешно перенесены',
            });
            refetch();
            cancel();
        },
        onError: function (error) {
            console.error(error);
            notification.error({
                message: 'Не удалось перенести настройки сервера',
            });
        },
    }), fetchParams = _b.mutate, isLoading = _b.isLoading;
    var getParams = function (values) {
        fetchParams(values);
    };
    return (<div style={{ padding: '20px 40px' }}>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={getParams}>
        <Form.Item label="Адрес сервера ЕСМ" name="url">
          <Input />
        </Form.Item>
        <Form.Item label="Токен ЕСМ" name="token">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className="form__buttons">
            <Button loading={isLoading} htmlType="submit" type="primary">Записать параметры</Button>
            <Button onClick={cancel}>Отменить</Button>
          </div>
        </Form.Item>
      </Form>
    </div>);
}
export default Migration;
