import dayjs from 'dayjs';
var getColumns = function () { return ([
    {
        dataIndex: 'date',
        title: 'Дата',
        width: 150,
        align: 'center',
        render: function (value) { return dayjs(value).format('DD.MM.YYYY'); },
    },
    {
        dataIndex: 'ticket_count',
        title: 'Всего обслужено',
        align: 'center',
    },
    {
        dataIndex: '00:00',
        title: '00:00 01:00',
        align: 'center',
    },
    {
        dataIndex: '01:00',
        title: '01:00 02:00',
        align: 'center',
    },
    {
        dataIndex: '02:00',
        title: '02:00 03:00',
        align: 'center',
    },
    {
        dataIndex: '03:00',
        title: '03:00 04:00',
        align: 'center',
    },
    {
        dataIndex: '04:00',
        title: '04:00 05:00',
        align: 'center',
    },
    {
        dataIndex: '05:00',
        title: '05:00 06:00',
        align: 'center',
    },
    {
        dataIndex: '06:00',
        title: '06:00 07:00',
        align: 'center',
    },
    {
        dataIndex: '07:00',
        title: '07:00 08:00',
        align: 'center',
    },
    {
        dataIndex: '08:00',
        title: '08:00 09:00',
        align: 'center',
    },
    {
        dataIndex: '09:00',
        title: '09:00 10:00',
        align: 'center',
    },
    {
        dataIndex: '10:00',
        title: '10:00 11:00',
        align: 'center',
    },
    {
        dataIndex: '11:00',
        title: '11:00 12:00',
        align: 'center',
    },
    {
        dataIndex: '12:00',
        title: '12:00 13:00',
        align: 'center',
    },
    {
        dataIndex: '13:00',
        title: '13:00 14:00',
        align: 'center',
    },
    {
        dataIndex: '14:00',
        title: '14:00 15:00',
        align: 'center',
    },
    {
        dataIndex: '15:00',
        title: '15:00 16:00',
        align: 'center',
    },
    {
        dataIndex: '16:00',
        title: '16:00  17:00',
        align: 'center',
    },
    {
        dataIndex: '17:00',
        title: '17:00 18:00',
        align: 'center',
    },
    {
        dataIndex: '18:00',
        title: '18:00 19:00',
        align: 'center',
    },
    {
        dataIndex: '19:00',
        title: '19:00 20:00',
        align: 'center',
    },
    {
        dataIndex: '20:00',
        title: '20:00 21:00',
        align: 'center',
    },
    {
        dataIndex: '21:00',
        title: '21:00 22:00',
        align: 'center',
    },
    {
        dataIndex: '22:00',
        title: '22:00 23:00',
        align: 'center',
    },
    {
        dataIndex: '23:00',
        title: '23:00 00:00',
        align: 'center',
    },
]); };
export default getColumns;
