var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, notification, Select, Tabs, TimePicker, } from 'antd';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import preorder from '@/utils/rest/preorder';
function CreateSlot(_a) {
    var officeId = _a.officeId, queues = _a.queues, days = _a.days, refetch = _a.refetch, closeDrawer = _a.closeDrawer, getDays = _a.getDays;
    var _b = useState(false), forAll = _b[0], setForAll = _b[1];
    var _c = useState(false), overwrite = _c[0], setOverwrite = _c[1];
    var _d = useState('single'), activeTab = _d[0], setActiveTab = _d[1];
    var _e = useState([]), selectedQueues = _e[0], setSelectedQueues = _e[1];
    var _f = useState(), selectedDate = _f[0], setSelectedDate = _f[1];
    var _g = useState(), selectedTime = _g[0], setSelectedTime = _g[1];
    var _h = useState(), calendarDay = _h[0], setCalendarDay = _h[1];
    var _j = useState({
        interval: 15,
        count: 5,
    }), populate = _j[0], setPopulate = _j[1];
    var _k = useMutation({
        mutationFn: function (variables) { return preorder.createSlot(variables.officeId, variables.data); },
        onSuccess: function () {
            notification.success({
                message: 'Слоты успешно созданы',
            });
            refetch();
            closeDrawer();
        },
        onError: function () {
            notification.error({
                message: 'Не удалось создать слоты',
            });
        },
    }), mutate = _k.mutate, isLoading = _k.isLoading;
    var selectDate = function (date) {
        var day = date && (days === null || days === void 0 ? void 0 : days.find(function (el) { return (el.monthNumber === (date.month() + 1) && el.dayOfMonth === (date === null || date === void 0 ? void 0 : date.date()) && el.yearNumber === (date === null || date === void 0 ? void 0 : date.year())); }));
        if (day) {
            setSelectedDate(date === null || date === void 0 ? void 0 : date.format('YYYY.MM.DD'));
            setCalendarDay(day);
        }
        else if (officeId && date) {
            setSelectedDate(date.format('YYYY.MM.DD'));
            getDays({ officeId: officeId, year: date.year() });
        }
    };
    var changeTab = function (tab) {
        setActiveTab(tab);
    };
    var selectTime = function (value) {
        setSelectedTime(value === null || value === void 0 ? void 0 : value.format('HH:mm'));
    };
    var selectQueues = function (values) {
        setSelectedQueues(values);
    };
    var selectTimeInterval = function (_timeInterval, timeIntervalString) {
        setSelectedTime(timeIntervalString);
    };
    var selectSlotCount = function (e) {
        var slotData = __assign({}, populate);
        slotData[e.target.name] = Number(e.target.value);
        setPopulate(slotData);
    };
    var createSlots = function () {
        var qArr = __spreadArray([], selectedQueues, true);
        if (forAll) {
            qArr.splice(0, qArr.length);
            queues === null || queues === void 0 ? void 0 : queues.forEach(function (el) {
                if (el.qId !== 0) {
                    qArr.push(el.qId);
                }
            });
        }
        if (calendarDay && officeId) {
            if (activeTab === 'single') {
                mutate({
                    officeId: officeId,
                    data: {
                        begin: "".concat(selectedDate, " ").concat(selectedTime),
                        end: "".concat(selectedDate, " ").concat(selectedTime),
                        dayId: calendarDay.dayId,
                        queues: qArr,
                    },
                });
            }
            else if (activeTab === 'multiple') {
                mutate({
                    officeId: officeId,
                    data: {
                        begin: "".concat(selectedDate, " ").concat(selectedTime && selectedTime[0]),
                        end: "".concat(selectedDate, " ").concat(selectedTime && selectedTime[1]),
                        dayId: calendarDay.dayId,
                        queues: qArr,
                        populate: true,
                        replace: overwrite,
                        amount: populate.count,
                        step: populate.interval,
                    },
                });
            }
        }
    };
    useEffect(function () {
        if (days && selectedDate) {
            var day = days.find(function (el) { return (el.monthNumber === (dayjs(selectedDate).month() + 1)
                && el.dayOfMonth === dayjs(selectedDate).date()
                && el.yearNumber === dayjs(selectedDate).year()); });
            if (day)
                setCalendarDay(day);
        }
    }, [days]);
    var tabItems = [
        {
            key: 'single',
            label: 'Одиночное добавление слота',
            children: (<div className="preorder__slot__date">
          <div className="preorder__slot__date__select">
            <DatePicker format="DD.MM.YYYY" placeholder="Выберите дату" onChange={selectDate} style={{ width: '100%' }} showNow={false}/>
            <TimePicker format="HH:mm" minuteStep={5} placeholder="Выберите время" onChange={selectTime} style={{ width: '100%' }} showNow={false}/>
          </div>
          <Button onClick={createSlots} type="primary" disabled={!selectedTime
                    || (selectedQueues.length === 0 && !forAll)} loading={isLoading}>
            Добавить слоты
          </Button>
        </div>),
        },
        {
            key: 'multiple',
            label: 'Множественное добавление слотов',
            children: (<Form style={{ width: '600px' }} layout="vertical">
          <Form.Item label="Выберите день">
            <DatePicker style={{ width: '200px' }} format="DD.MM.YYYY" showNow={false} placeholder="Выберите день" onChange={selectDate}/>
          </Form.Item>
          <div className="preorder__form__row">
            <Form.Item label="Интервал времени (минуты)">
              <Input name="interval" type="number" onChange={selectSlotCount} defaultValue={15} step={5} min={5}/>
            </Form.Item>
            <Form.Item label="Кол-во слотов на одно время">
              <Input name="count" type="number" onChange={selectSlotCount} defaultValue={5} min={1}/>
            </Form.Item>
          </div>
          <Form.Item label="Интервал времени">
            <TimePicker.RangePicker onChange={selectTimeInterval} format="HH:mm" style={{ width: '320px' }} minuteStep={5} showNow={false}/>
          </Form.Item>
          <Form.Item>
            <Checkbox checked={overwrite} onChange={function (e) { return setOverwrite(e.target.checked); }}>
              Перезаписывать существующие слоты
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button disabled={!selectedTime
                    || !Array.isArray(selectedTime)
                    || (selectedQueues.length === 0 && !forAll)} onClick={createSlots} type="primary" loading={isLoading}>
              Добавить слоты
            </Button>
          </Form.Item>
        </Form>),
        },
    ];
    return (<div>
      <Form style={{ width: '600px' }} layout="vertical">
        <Form.Item label="Список услуг для добавления слотов">
          <Select disabled={forAll} style={{ width: '100%' }} mode="multiple" onChange={selectQueues} placeholder="Выберите услуги">
            {queues === null || queues === void 0 ? void 0 : queues.map(function (queue) { return (<Select.Option key={queue.qId} value={Number(queue.qId)}>
                {queue.qFullName}
              </Select.Option>); })}
          </Select>
        </Form.Item>
      </Form>
      <Checkbox checked={forAll} onChange={function (e) { return setForAll(e.target.checked); }}>
        Добавить слоты по всем услугам
      </Checkbox>
      <Tabs onChange={changeTab} style={{ marginTop: '20px' }} items={tabItems}/>
    </div>);
}
export default CreateSlot;
