import React, { useState } from 'react';
import { Button, Card, Form, Input, Row, Statistic, Table, Col, } from 'antd';
import { useMutation } from 'react-query';
import { getMigrationColumns } from '@/pages/Offices/tableColumns';
import offices from '@/utils/rest/offices';
function Migration(_a) {
    var cancel = _a.cancel, refetch = _a.refetch;
    var _b = useState(), officeList = _b[0], setOfficeList = _b[1];
    var _c = useState(), companiesList = _c[0], setCompaniesList = _c[1];
    var _d = useState(), selectedOffices = _d[0], setSelectedOffices = _d[1];
    var _e = useState(), selectedRowKeys = _e[0], setSelectedRowKeys = _e[1];
    var mutate = useMutation({
        mutationFn: function (variables) { return offices.getOfficeList(variables); },
        onSuccess: function (data) {
            var selectedKeys = [];
            var selected = [];
            data.offices.forEach(function (office) {
                selected.push(office);
                selectedKeys.push(office.id);
            });
            setSelectedRowKeys(selectedKeys);
            setSelectedOffices(selected);
            setOfficeList(data.offices);
            setCompaniesList(data.companies);
        },
    }).mutate;
    var _f = useMutation({
        mutationFn: function (variables) { return offices.migration(variables); },
        onSuccess: function () {
            refetch();
            cancel();
        },
    }), migrationMutate = _f.mutate, isLoading = _f.isLoading;
    var getOffices = function (values) {
        mutate(values);
    };
    var updateOffices = function () {
        if (selectedOffices && companiesList) {
            migrationMutate({ offices: selectedOffices, companies: companiesList });
        }
    };
    return (<div style={{ padding: '20px 40px' }}>
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={getOffices}>
        <Form.Item label="Адрес сервера ЕСМ" name="url">
          <Input />
        </Form.Item>
        <Form.Item label="Токен ЕСМ" name="token">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <div className="form__buttons">
            <Button htmlType="submit" type="primary">Получить список офисов</Button>
            <Button onClick={cancel}>Отменить</Button>
          </div>
        </Form.Item>
      </Form>
      {officeList
            && (<div>
            <Row gutter={16}>
              <Col>
                <Card>
                  <Statistic title="Всего будет добавлено офисов" value={officeList.length}/>
                </Card>
              </Col>
              {companiesList === null || companiesList === void 0 ? void 0 : companiesList.map(function (company) { return (<Col>
                  <Card>
                    <Statistic title={company.id ? company.name : "".concat(company.name, " (\u0431\u0443\u0434\u0435\u0442 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0430 \u0432 \u0431\u0430\u0437\u0443)")} value={company.offices.length}/>
                  </Card>
                </Col>); })}
            </Row>
            <div style={{ padding: '20px 0' }}/>
            <Table rowKey="id" dataSource={officeList} columns={getMigrationColumns()} rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRowKeys,
                    onChange: function (keys, selectedRows) {
                        setSelectedRowKeys(keys);
                        setSelectedOffices(selectedRows);
                    },
                }}/>
            <Button type="primary" loading={isLoading} onClick={updateOffices}>Занести офисы в базу</Button>
          </div>)}
    </div>);
}
export default Migration;
