import React from 'react';
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { isAdmin } from '@/utils/checkRole';
var getColumns = function (deleteGroup, handleGroup, role) {
    var columns = [
        {
            title: 'Название',
            dataIndex: 'name',
            width: 280,
        },
        {
            title: 'Описание',
            dataIndex: 'description',
        },
        {
            title: 'Офисы',
            dataIndex: 'offices',
            width: 400,
            render: function (offices) { return (offices.map(function (office) { return (<p key={office.office_id}>{office.office_name}</p>); })); },
        },
    ];
    if (isAdmin(role)) {
        columns.push({
            key: 'actions',
            width: 80,
            render: function (_, row) { return (<div className="table__button__box">
          <button className="table__button" type="button" onClick={function () { return handleGroup(row); }}>
            <EditTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <Popconfirm title="Удалить группировку?" onConfirm={function () { return deleteGroup(row.id); }}>
            <button className="table__button" type="submit">
              <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
            </button>
          </Popconfirm>
        </div>); },
        });
    }
    return columns;
};
export default getColumns;
