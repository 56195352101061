import React from 'react';
import { Tabs } from 'antd';
import MultipleOfficeSlots from '@/pages/PreRecord/components/multipleOfficeSlots';
import SingleOfficeSlots from '@/pages/PreRecord/components/singleOfficeSlots';
import './prerecord.css';
function PreRecord() {
    var tabs = [
        {
            key: '1',
            label: 'Добавление слотов по офисам',
            children: <SingleOfficeSlots />,
        },
        {
            key: '2',
            label: 'Добавление слотов для всех офисов',
            children: <MultipleOfficeSlots />,
        },
    ];
    return (<Tabs items={tabs} defaultActiveKey="1"/>);
}
export default PreRecord;
