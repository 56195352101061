import React from 'react';
import { Button, Form, Input } from 'antd';
function CompanyEdit(_a) {
    var company = _a.company, cancel = _a.cancel, handleCompany = _a.handleCompany;
    return (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={company} onFinish={handleCompany}>
      <Form.Item label="Имя компании" name="name" rules={[{ required: true, message: 'Введите название компании' }]}>
        <Input placeholder="Введите название компании"/>
      </Form.Item>
      <Form.Item label="Описание компании" name="description">
        <Input placeholder="Введите описание компании"/>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div className="form__buttons">
          <Button htmlType="submit" type="primary">Сохранить</Button>
          <Button onClick={cancel}>Отменить</Button>
        </div>
      </Form.Item>
    </Form>);
}
export default CompanyEdit;
