import React, { useEffect, useRef, useState, } from 'react';
import { Skeleton, Statistic, Table, } from 'antd';
import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import Filters from '@/components/Filters';
import getColumns from '@/pages/Reports/ticketReport/tableColumns';
import { AppStore, FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
import '@/pages/Reports/reports.css';
import secToTime from '@/utils/rest/secToTime';
function TicketReports() {
    var firstRender = useRef(true);
    var filters = FilterStore.useState();
    var showDays = AppStore.useState(function (s) { return s.showDays; });
    var _a = useState(), reportData = _a[0], setReportData = _a[1];
    var _b = useState(), sort = _b[0], setSort = _b[1];
    var _c = useState({
        page: 0,
        size: 10,
    }), pagination = _c[0], setPagination = _c[1];
    var _d = useQuery('statistic', function () { return report.getReport({
        startDate: filters.dates && (filters === null || filters === void 0 ? void 0 : filters.dates[0]) ? filters.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
        endDate: filters.dates && (filters === null || filters === void 0 ? void 0 : filters.dates[1]) ? filters.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
        reportType: 'ticketReportTitle',
        filters: filters,
    }); }), statData = _d.data, isStatLoading = _d.isLoading, isStatFetching = _d.isFetching, refetch = _d.refetch;
    var _e = useMutation({
        mutationFn: function (variables) { return report.getReport({
            startDate: variables.dates && (variables === null || variables === void 0 ? void 0 : variables.dates[0]) ? variables.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
            endDate: variables.dates && (variables === null || variables === void 0 ? void 0 : variables.dates[1]) ? variables.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
            reportType: variables.reportType,
            limit: variables.limit,
            offset: variables.offset,
            filters: filters,
            sort: sort,
        }); },
        onSuccess: function (data) {
            setReportData(data);
        },
    }), mutate = _e.mutate, isLoading = _e.isLoading;
    var fetchData = function () {
        mutate({
            dates: filters.dates, reportType: 'ticketReport', limit: pagination.size, offset: pagination.page,
            filters: filters,
            sort: sort,
        });
        refetch();
    };
    var changePagination = function (page, pageSize) {
        setPagination({ page: ((page - 1) * pageSize), size: pageSize });
        mutate({
            dates: filters.dates, reportType: 'ticketReport', limit: pageSize, offset: ((page - 1) * pageSize),
            filters: filters,
            sort: sort,
        });
    };
    var changeSort = function (_tablePagination, _tableFilters, sortOption) {
        if (!Array.isArray(sortOption)) {
            var field = sortOption.field, order = sortOption.order;
            if (field && order) {
                setSort({ field: field, order: order });
            }
            else {
                setSort(undefined);
            }
        }
    };
    useEffect(function () {
        fetchData();
    }, [filters]);
    useEffect(function () {
        if (firstRender.current) {
            firstRender.current = false;
        }
        else {
            mutate({
                dates: filters.dates, reportType: 'ticketReport', limit: pagination.size, offset: pagination.page,
                filters: filters,
                sort: sort,
            });
        }
    }, [sort]);
    return (<>
      <Filters refetch={fetchData} filterItems={['companies', 'offices', 'wplaces', 'queues', 'operators', 'status']} reportType="ticketReport" totalCount={(reportData === null || reportData === void 0 ? void 0 : reportData.total_rows) || 0} isExport/>
      <div className="report__statistic">
        {!isStatLoading && !isStatFetching ? (<>
            <Statistic title="Всего отделений" value={Number(statData === null || statData === void 0 ? void 0 : statData.statistic.office_count)}/>
            <Statistic title="Всего операторов" value={Number(statData === null || statData === void 0 ? void 0 : statData.statistic.operator_count)}/>
            <Statistic title="Всего услуг" value={Number(statData === null || statData === void 0 ? void 0 : statData.statistic.queue_count)}/>
            <Statistic title="Всего клиентов" value={Number(statData === null || statData === void 0 ? void 0 : statData.statistic.ticket_count)}/>
            <Statistic title="Общая длительность ожидания" value={secToTime(Number(statData === null || statData === void 0 ? void 0 : statData.statistic.total_wait), showDays)}/>
            <Statistic title="Средняя длительность ожидания" value={secToTime(Number(statData === null || statData === void 0 ? void 0 : statData.statistic.avg_wait), showDays)}/>
            <Statistic title="Общая длительность обслуживания" value={secToTime(Number(statData === null || statData === void 0 ? void 0 : statData.statistic.total_proc), showDays)}/>
            <Statistic title="Средняя длительность обслуживания" value={secToTime(Number(statData === null || statData === void 0 ? void 0 : statData.statistic.avg_proc), showDays)}/>
          </>) : (<>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
            <Skeleton.Input active/>
          </>)}
      </div>
      <Table rowKey="id" dataSource={reportData === null || reportData === void 0 ? void 0 : reportData.items} loading={isLoading} columns={getColumns()} onChange={changeSort} pagination={{
            total: reportData === null || reportData === void 0 ? void 0 : reportData.total_rows,
            defaultCurrent: 1,
            onChange: changePagination,
        }} scroll={{ x: 2800 }}/>
    </>);
}
export default TicketReports;
