var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Button, Checkbox, Form, notification, Select, } from 'antd';
import { useMutation } from 'react-query';
import preorder from '@/utils/rest/preorder';
function RewriteSlot(_a) {
    var officeId = _a.officeId, queues = _a.queues, dayId = _a.dayId, refetch = _a.refetch, closeDrawer = _a.closeDrawer;
    var _b = useState([]), selectedQueues = _b[0], setSelectedQueues = _b[1];
    var _c = useState(false), forAll = _c[0], setForAll = _c[1];
    var mutate = useMutation({
        mutationFn: function (variables) { return preorder.rewriteSlots(variables.officeId, variables.data); },
        onSuccess: function () {
            notification.success({
                message: 'Слоты успешно перезаписаны',
            });
            refetch();
            closeDrawer();
        },
        onError: function () {
            notification.error({
                message: 'Не удалось перезаписать слоты',
            });
        },
    }).mutate;
    var handleSelect = function (values) {
        setSelectedQueues(values);
    };
    var handleCheck = function (e) {
        setForAll(e.target.checked);
    };
    var rewriteSlots = function () {
        if (officeId) {
            var qArr_1 = __spreadArray([], selectedQueues, true);
            if (forAll) {
                queues === null || queues === void 0 ? void 0 : queues.forEach(function (el) {
                    if (el.qId !== 0) {
                        qArr_1.push(el.qId);
                    }
                });
            }
            mutate({ officeId: officeId, data: { queues: qArr_1, dayId: dayId } });
        }
    };
    return (<Form style={{ width: '600px' }} layout="vertical">
      <Form.Item style={{ width: '100%' }} label="Список услуг для перезаписи слотов">
        <Select onChange={handleSelect} mode="multiple">
          {queues === null || queues === void 0 ? void 0 : queues.map(function (queue) { return (<Select.Option key={queue.qId} value={Number(queue.qId)}>
              {queue.qFullName}
            </Select.Option>); })}
        </Select>
      </Form.Item>
      <Form.Item>
        <Checkbox onChange={handleCheck}>
          Перезаписать все слоты по всем услугам
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Button onClick={rewriteSlots} disabled={selectedQueues.length === 0 && !forAll} type="primary">
          Перезаписать слоты
        </Button>
      </Form.Item>
    </Form>);
}
export default RewriteSlot;
