import React, { useState } from 'react';
import { SaveTwoTone } from '@ant-design/icons';
import { Input } from 'antd';
import dayjs from 'dayjs';
function OfficeEditCell(_a) {
    var row = _a.row, onSave = _a.onSave;
    var _b = useState(), value = _b[0], setValue = _b[1];
    var _c = useState(false), isChanged = _c[0], setIsChanged = _c[1];
    var defaultValue = row.Handlings.sort(function (a, b) { return (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1); });
    var handleInput = function (e) {
        setIsChanged(true);
        setValue(e.target.value);
    };
    var saveComment = function () {
        if (value) {
            onSave(row, value);
            setIsChanged(false);
        }
        else {
            onSave(row, '');
            setIsChanged(false);
        }
    };
    return (<div className="settings__input">
      <Input defaultValue={row.Handlings.length > 0 ? defaultValue[0].comment : ''} onChange={handleInput}/>
      {isChanged && <SaveTwoTone onClick={saveComment} style={{ fontSize: '30px' }}/>}
    </div>);
}
export default OfficeEditCell;
