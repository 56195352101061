import React from 'react';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { dateTimeFormat } from '@/utils/constants';
function Filters(_a) {
    var dates = _a.dates, handleChange = _a.handleChange, fetchData = _a.fetchData;
    return (<div className="filter__box">
      <DatePicker.RangePicker format={dateTimeFormat} onChange={handleChange} value={dates} showTime={{
            defaultValue: [
                dayjs().startOf('day'),
                dayjs().endOf('day'),
            ],
        }} presets={[
            {
                label: 'За предыдущий месяц',
                value: [
                    dayjs().add(-1, 'M').startOf('month'),
                    dayjs().add(-1, 'M').endOf('month'),
                ],
            },
            {
                label: 'За год',
                value: [
                    dayjs().startOf('year'),
                    dayjs().endOf('day'),
                ],
            },
        ]} style={{ width: '400px' }}/>
      <div className="filter__button__box">
        <Button className="filter__button" type="primary" onClick={fetchData}>Применить</Button>
      </div>
    </div>);
}
export default Filters;
