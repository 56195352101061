import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getInfoTableColumns } from '@/pages/Reports/queueReport/tableColumns';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function QueueInfo(_a) {
    var queue = _a.queue, dates = _a.dates;
    var _b = useQuery(['queue', queue.queue_id, queue.office_id, dates], function () { return report.getQueue(queue.queue_id, queue.office_id, dates && dates[0] ? dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat), dates && dates[1] ? dates[1].format(seqTimeFormat) : dayjs().endOf('month').format(seqTimeFormat)); }), data = _b.data, isLoading = _b.isLoading;
    return (<div>
      <Table rowKey="id" dataSource={data} loading={isLoading} columns={getInfoTableColumns()}/>
    </div>);
}
export default QueueInfo;
