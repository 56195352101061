import React, { useEffect } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/components/Filters';
import getColumns from '@/pages/Reports/hourlyReport/tableColumns';
import { FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import reports from '@/utils/rest/report';
function HourlyReport() {
    var filters = FilterStore.useState();
    var _a = useQuery('hourlyReport', function () { return reports.getReport({
        startDate: filters.dates && filters.dates[0] ? filters.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
        endDate: filters.dates && filters.dates[1] ? filters.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
        reportType: 'hourlyReport',
        filters: filters,
    }); }), data = _a.data, isLoading = _a.isLoading, isFetching = _a.isFetching, refetch = _a.refetch;
    useEffect(function () {
        refetch();
    }, [filters]);
    return (<>
      <Filters refetch={refetch} filterItems={['companies', 'offices', 'wplaces', 'queues', 'operators']} reportType="hourlyReport" totalCount={(data === null || data === void 0 ? void 0 : data.items.length) || 0} isExport/>
      <Table rowKey="date" dataSource={data === null || data === void 0 ? void 0 : data.items} loading={isLoading || isFetching} columns={getColumns()} scroll={{ x: 2700 }}/>
    </>);
}
export default HourlyReport;
