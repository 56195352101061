import dayjs from 'dayjs';
import { Store } from 'pullstate';
export var InitialFilters = {
    dates: [dayjs().startOf('month'), dayjs().endOf('day')],
    offices: [],
    companies: [],
    operators: [],
    queues: [],
    wplaces: [],
};
export var UserStore = new Store({
    fullname: '',
    role: '',
});
export var AppStore = new Store({
    updateSider: undefined,
    updateIndicators: undefined,
    params: undefined,
    mapInit: false,
    showDays: localStorage.getItem('days') === 'false',
});
export var FilterStore = new Store(InitialFilters);
