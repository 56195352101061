import React, { useState } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/pages/Reports/groupReport/filters';
import getColumns from '@/pages/Reports/groupReport/tableColumns';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function GroupReport() {
    var _a = useState([
        dayjs().startOf('month'),
        dayjs().endOf('day'),
    ]), dates = _a[0], setDates = _a[1];
    var _b = useQuery('groups', function () { return report.getReport({
        startDate: dates[0].format(seqTimeFormat),
        endDate: dates[1].format(seqTimeFormat),
        reportType: 'groupReport',
    }); }), data = _b.data, isFetching = _b.isFetching, refetch = _b.refetch;
    var handleChange = function (values, strDates) {
        var newStartDate = values ? dayjs(strDates[0], 'DD.MM.YYYY HH:mm') : dayjs().startOf('month');
        var newEndDate = values ? dayjs(strDates[1], 'DD.MM.YYYY HH:mm') : dayjs().endOf('day');
        setDates([newStartDate, newEndDate]);
    };
    return (<>
      <Filters handleChange={handleChange} dates={dates} fetchData={refetch}/>
      <Table dataSource={data && data.items} loading={isFetching} columns={getColumns()}/>
    </>);
}
export default GroupReport;
