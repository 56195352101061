var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { DeleteTwoTone, SaveTwoTone } from '@ant-design/icons';
import { Popconfirm, Progress } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { timeDateFormat } from '@/utils/constants';
import getColumnSearchProps from '@/utils/tableSearch';
var reportTypes = {
    ticketReport: 'Отчёт по талонам',
    operatorReport: 'Отчёт по операторам',
    queueReport: 'Отчёт по услугам',
    officeReport: 'Отчёт по офисам',
    hourlyReport: 'Почасовой отчёт',
    monitoring: 'Мониторинг',
    groupReport: 'По группировкам',
    questionReport: 'По оценке качества',
    ftfService: 'Статистика по офисам',
};
var reportStatuses = {
    0: 'В очереди',
    1: 'Готовится',
    2: 'Готов для скачивания',
    3: 'Ошибка',
};
var getColumns = function (downloadFile, handleDelete) { return [
    {
        title: 'Дата создания',
        dataIndex: 'createdAt',
        render: function (_, row) { return moment(row.createdAt).format(timeDateFormat); },
        sorter: function (a, b) { return (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1); },
    },
    __assign(__assign({ title: 'Название файла', dataIndex: 'name' }, getColumnSearchProps('name', 'по названию отчёта')), { sorter: function (a, b) { return ([
            a.name.toLowerCase(),
            b.name.toLowerCase(),
        ].sort()[0] === a.name.toLowerCase() ? -1 : 1); } }),
    __assign(__assign({ title: 'Тип отчета', dataIndex: 'report_type', render: function (_, row) { return reportTypes[row.report_type]; } }, getColumnSearchProps('report_type', 'по типу отчёта')), { sorter: function (a, b) { return ([
            a.report_type.toLowerCase(),
            b.report_type.toLowerCase(),
        ].sort()[0] === a.report_type.toLowerCase() ? -1 : 1); } }),
    {
        title: 'Статус',
        dataIndex: 'report_status',
        render: function (_, row) { return reportStatuses[row.report_status]; },
    },
    {
        title: 'Действия',
        dataIndex: 'actions',
        render: function (_, row) { return (row.progress < 100 ? (<Progress type="circle" percent={row.progress} size={40}/>) : (<div className="table__button__box">
          <button className="table__button" onClick={function () { return downloadFile(row); }} type="button">
            <SaveTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <Popconfirm title="Удалить отчёт?" onConfirm={function () { return handleDelete(row); }}>
            <button className="table__button" type="button">
              <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
            </button>
          </Popconfirm>
        </div>)); },
    },
]; };
export default getColumns;
