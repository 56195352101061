import React, { useEffect } from 'react';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/components/Filters';
import OfficesList from '@/pages/Reports/officeReport/officesList';
import { FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
import './officeReport.css';
function OfficeReport() {
    var filters = FilterStore.useState();
    var _a = useQuery('officeReport', function () { return report.getReport({
        startDate: filters.dates && filters.dates[0] ? filters.dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat),
        endDate: filters.dates && filters.dates[1] ? filters.dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat),
        reportType: 'officeReport',
        filters: filters,
    }); }), isFetching = _a.isFetching, isLoading = _a.isLoading, reportData = _a.data, refetch = _a.refetch;
    useEffect(function () {
        refetch();
    }, [filters]);
    return (<>
      <Filters refetch={refetch} filterItems={['companies', 'offices']} reportType="officeReport" totalCount={(reportData === null || reportData === void 0 ? void 0 : reportData.items.length) || 0} isExport/>
      <div className="office__card__box">
        {isLoading || isFetching
            ? <Skeleton active/>
            : (<OfficesList dates={filters.dates} offices={reportData === null || reportData === void 0 ? void 0 : reportData.items}/>)}
      </div>
    </>);
}
export default OfficeReport;
