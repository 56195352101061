import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Filters from '@/components/Filters';
import tableColumns from '@/pages/Reports/officeStatistic/tableColumns';
import { FilterStore } from '@/store';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function OfficeStatistic() {
    var toHours = window.localStorage.getItem('days');
    var _a = useState([]), tableData = _a[0], setTableData = _a[1];
    var filters = FilterStore.useState();
    var _b = useQuery('officeStatisticReport', function () {
        var _a, _b;
        return report.getReport({
            startDate: filters && filters.dates && filters.dates[0]
                ? (_a = filters.dates[0]) === null || _a === void 0 ? void 0 : _a.format(seqTimeFormat)
                : dayjs().startOf('month').format(seqTimeFormat),
            endDate: filters && filters.dates && filters.dates[1]
                ? (_b = filters === null || filters === void 0 ? void 0 : filters.dates[1]) === null || _b === void 0 ? void 0 : _b.format(seqTimeFormat)
                : dayjs().endOf('day').format(seqTimeFormat),
            reportType: 'ftfService',
            filters: filters,
        });
    }, {
        onSuccess: function (data) { return setTableData(data.items); },
        enabled: !!filters.dates,
    }), isLoading = _b.isLoading, isFetching = _b.isFetching, refetch = _b.refetch;
    useEffect(function () {
        refetch();
    }, [filters]);
    return (<>
      <Filters reportType="ftfService" totalCount={tableData.length} refetch={refetch} filterItems={['companies', 'offices']} isExport/>
      <Table rowKey="id" dataSource={tableData} loading={isLoading || isFetching} columns={tableColumns(Boolean(toHours))}/>
    </>);
}
export default OfficeStatistic;
