var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Card, Collapse, Modal, notification, Radio, Table, } from 'antd';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import Cameras from '@/pages/Map/Cameras';
import getColumns from '@/pages/Monitoring/tableColumns';
import report from '@/utils/rest/report';
import settings from '@/utils/rest/settings';
import './monitoring.css';
var tableWidth = {
    1: 2400,
    2: 2200,
    3: 2100,
    4: 2000,
    5: 1900,
    6: 1500,
};
var filterColumns = [
    {
        title: 'Время последней синхронизации',
    },
    {
        title: 'Название отделения',
    },
    {
        title: 'Адрес',
    },
    {
        title: 'Талоны',
    },
    {
        title: 'Рабочие места',
    },
    {
        title: 'Средняя длительность ожидания',
    },
    {
        title: 'Средняя длительность обслуживания',
    },
    {
        title: 'Максимальная длительность ожидания',
    },
    {
        title: 'Средняя оценка',
    },
    {
        title: 'Статус синхронизации',
    },
    {
        title: 'Максимальная длительность ожидания за день',
    },
];
function Monitoring() {
    var _a;
    var columns = localStorage.getItem('columns');
    var _b = useState(), statistic = _b[0], setStatistic = _b[1];
    var _c = useState(), tableData = _c[0], setTableData = _c[1];
    var _d = useState(1), status = _d[0], setStatus = _d[1];
    var _e = useState(columns ? JSON.parse(columns) : []), filteredColumns = _e[0], setFilteredColumns = _e[1];
    var _f = useState(), currentOffice = _f[0], setCurrentOffice = _f[1];
    var _g = useQuery('settings', settings.getAll), isLoadingParams = _g.isLoading, paramsData = _g.data;
    var _h = useQuery('monitoring', function () { return report.getMonitoring(['office_name', 'ASC']); }, {
        enabled: !isLoadingParams,
        refetchInterval: Number((_a = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'monitoringUpdateFrequency'; })) === null || _a === void 0 ? void 0 : _a.value) * 1000,
        onSuccess: function (res) {
            if (status !== undefined) {
                setTableData(res.filter(function (el) { return el.sync_status === status; }));
            }
            else {
                setTableData(res);
            }
        },
    }), isLoading = _h.isLoading, data = _h.data, refetch = _h.refetch;
    var mutate = useMutation({
        mutationFn: function () { return report.export('', '', 'monitoring'); },
        onSuccess: function () {
            notification.success({
                message: 'Отчёт подготавливается к скачиванию, скачать отчёт можно в разделе Архив',
            });
        },
    }).mutate;
    var getStatistic = function () {
        var statData = {
            wplaces: {
                wplaces_online: 0,
                wplaces_busy: 0,
                wplaces_idle: 0,
            },
            time: {
                avg_proc_time: 0,
                avg_wait_time: 0,
                max_wait_today: 0,
            },
            tickets: {
                count_all: 0,
                count_closed: 0,
                count_deleted: 0,
                count_in_process: 0,
                count_waiting: 0,
                total_now: 0,
            },
        };
        data === null || data === void 0 ? void 0 : data.forEach(function (el) {
            statData.wplaces.wplaces_online += el.wplaces_online;
            statData.wplaces.wplaces_busy += el.wplaces_busy;
            statData.wplaces.wplaces_idle += el.wplaces_idle;
            statData.time.avg_wait_time += el.avg_wait_time;
            statData.time.avg_proc_time += el.avg_proc_time;
            if (el.max_wait_today > statData.time.max_wait_today) {
                statData.time.max_wait_today = el.max_wait_today;
            }
            statData.tickets.count_all += el.count_all;
            statData.tickets.count_closed += el.count_closed;
            statData.tickets.count_deleted += el.count_deleted;
            statData.tickets.count_in_process += el.count_in_process;
            statData.tickets.count_waiting += el.count_waiting;
        });
        statData.tickets.total_now += Number(statData.tickets.count_in_process + statData.tickets.count_waiting);
        statData.time.avg_proc_time = Number(statData.time.avg_proc_time / Number(data === null || data === void 0 ? void 0 : data.length));
        statData.time.avg_wait_time = Number(statData.time.avg_wait_time / Number(data === null || data === void 0 ? void 0 : data.length));
        setStatistic(statData);
    };
    var checkColumns = function (e) {
        var prevState = __spreadArray([], filteredColumns, true);
        var _a = e.target, checked = _a.checked, name = _a.name;
        if (name) {
            if (checked) {
                var idx = prevState.findIndex(function (el) { return el === name; });
                prevState.splice(idx, 1);
                setFilteredColumns(prevState);
            }
            else {
                prevState.push(name);
                setFilteredColumns(prevState);
            }
            localStorage.setItem('columns', JSON.stringify(prevState));
        }
    };
    var filterOffice = function (e) {
        setStatus(e.target.value);
    };
    var tableColumns = getColumns(filterColumns, filteredColumns, checkColumns, data, setCurrentOffice).filter(function (column) { return !filteredColumns.includes(String(column.title)); });
    useEffect(function () {
        refetch();
    }, [status]);
    useEffect(function () {
        if (!isLoading) {
            getStatistic();
        }
    }, [data]);
    return (<>
      <h2 className="page__header">Мониторинг</h2>
      <div id="collapse">
        <Collapse style={{ marginBottom: '24px' }}>
          <Collapse.Panel key={1} header="Статистика">
            <div className="monitoring__statistic">
              <Card className="monitoring__statistic__card" title="Отделения">
                <div className="monitoring__statistic__text">
                  <p>Синхронизированные</p>
                  <p>{data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status === 1; }).length}</p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Не синхронизированные</p>
                  <p>{data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status === 0; }).length}</p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Синхронизация отключена</p>
                  <p>{data === null || data === void 0 ? void 0 : data.filter(function (el) { return !el.Office.sync; }).length}</p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Всего</p>
                  <p>{data === null || data === void 0 ? void 0 : data.length}</p>
                </div>
              </Card>
              <Card className="monitoring__statistic__card" title="Рабочие места">
                <div className="monitoring__statistic__text">
                  <p>Активно</p>
                  <p>{statistic === null || statistic === void 0 ? void 0 : statistic.wplaces.wplaces_online}</p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Свободных</p>
                  <p>{statistic === null || statistic === void 0 ? void 0 : statistic.wplaces.wplaces_idle}</p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Обслуживают</p>
                  <p>{statistic === null || statistic === void 0 ? void 0 : statistic.wplaces.wplaces_busy}</p>
                </div>
              </Card>
              <Card className="monitoring__statistic__card" title="Время обработки талонов">
                <div className="monitoring__statistic__text">
                  <p>Средняя длительность обслуживания</p>
                  <p>
                    {moment
            .utc(Number(statistic === null || statistic === void 0 ? void 0 : statistic.time.avg_proc_time) * 1000)
            .format('HH:mm:ss')}
                  </p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Средняя длительность ожидания</p>
                  <p>
                    {moment
            .utc(Number(statistic === null || statistic === void 0 ? void 0 : statistic.time.avg_wait_time) * 1000)
            .format('HH:mm:ss')}
                  </p>
                </div>
                <div className="monitoring__statistic__text">
                  <p>Максимальное время ожидания</p>
                  <p>
                    {moment
            .utc(Number(statistic === null || statistic === void 0 ? void 0 : statistic.time.max_wait_today) * 1000)
            .format('HH:mm:ss')}
                  </p>
                </div>
              </Card>
              <Card className="monitoring__statistic__card" title="Талоны">
                <div className="monitoring__statistic__subcard__box">
                  <Card bordered={false} style={{ boxShadow: 'none' }} className="monitoring__statistic__subcard" title="В работе">
                    <div className="monitoring__statistic__text">
                      <p>В обслуживании</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.count_in_process}</p>
                    </div>
                    <div className="monitoring__statistic__text">
                      <p>Ожидает</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.count_waiting}</p>
                    </div>
                    <div className="monitoring__statistic__text">
                      <p>Всего</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.total_now}</p>
                    </div>
                  </Card>
                  <Card bordered={false} style={{ boxShadow: 'none' }} className="monitoring__statistic__subcard" title="Итог за день">
                    <div className="monitoring__statistic__text">
                      <p>Закрыто</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.count_closed}</p>
                    </div>
                    <div className="monitoring__statistic__text">
                      <p>Неявка</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.count_deleted}</p>
                    </div>
                    <div className="monitoring__statistic__text">
                      <p>Всего</p>
                      <p>{statistic === null || statistic === void 0 ? void 0 : statistic.tickets.count_all}</p>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className="monitoring__button__layout">
        <div className="monitoring__button__box">
          <Radio.Group value={status} onChange={filterOffice}>
            <Radio.Button value={1}>
              Синхронизированные
              <span style={{ margin: '0 0 0 5px' }}>
                (
                {data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status === 1; }).length}
                )
              </span>
            </Radio.Button>
            <Radio.Button value={0}>
              С ошибкой синхронизации
              <span style={{ margin: '0 0 0 5px' }}>
                (
                {data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status === 0; }).length}
                )
              </span>
            </Radio.Button>
            <Radio.Button value={2}>
              Синхронизация отключена
              <span style={{ margin: '0 0 0 5px' }}>
                (
                {data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status === 2; }).length}
                )
              </span>
            </Radio.Button>
            <Radio.Button value={undefined}>
              Всего
              <span style={{ margin: '0 0 0 5px' }}>
                (
                {data === null || data === void 0 ? void 0 : data.length}
                )
              </span>
            </Radio.Button>
          </Radio.Group>
        </div>
        <Button onClick={function () { return mutate(); }}>Экспортировать</Button>
      </div>
      <Modal open={!!currentOffice} onCancel={function () { return setCurrentOffice(undefined); }} destroyOnClose footer={null} width={1400}>
        <Cameras cameras={currentOffice && JSON.parse(currentOffice.Office.info).cameras}/>
      </Modal>
      <Table rowKey="office_id" loading={isLoading} dataSource={tableData} size="small" scroll={{
            x: (filteredColumns.length === 0
                ? 2600
                : tableWidth[filteredColumns.length]),
            y: window.innerHeight - 400,
        }} columns={tableColumns}/>
    </>);
}
export default Monitoring;
