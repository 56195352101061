var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import '@/pages/Map/map.css';
import { EnvironmentFilled } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import { useQuery } from 'react-query';
import { ReactComponent as FSoff } from '@/assets/icons/fsOff.svg';
import { ReactComponent as FSon } from '@/assets/icons/fsOn.svg';
import { getMapIcon, hint } from '@/pages/Map/mapUtils';
import OfficeList from '@/pages/Map/officeList';
import { AppStore } from '@/store';
import report from '@/utils/rest/report';
import settings from '@/utils/rest/settings';
import YMap from '@/utils/yandexMap';
function Map() {
    var _a, _b, _c, _d, _e, _f;
    var mapInit = AppStore.useState(function (state) { return state.mapInit; });
    var _g = useState(false), fullscreen = _g[0], setFullscreen = _g[1];
    var _h = useState(), yandexMapObj = _h[0], setYandexMapObj = _h[1];
    var _j = useState('DESC'), sortType = _j[0], setSortType = _j[1];
    var _k = useState('office_name'), sortValue = _k[0], setSortValue = _k[1];
    var _l = useQuery('settings', settings.getAll), isLoadingParams = _l.isLoading, paramsData = _l.data;
    var _m = useQuery('monitoring', function () { return report.getMonitoring([sortValue, sortType]); }, {
        enabled: !isLoadingParams,
        refetchInterval: Number((_a = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'monitoringUpdateFrequency'; })) === null || _a === void 0 ? void 0 : _a.value) * 1000,
    }), isLoading = _m.isLoading, data = _m.data, refetch = _m.refetch;
    var map;
    var timePoints = {
        wait: { max: 0, min: 0, colors: { max: '', min: '', mid: '' } },
        proc: { max: 0, min: 0, colors: { max: '', min: '', mid: '' } },
    };
    var waitePoints = (_b = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'averageWaitTimeMapColorGradation'; })) === null || _b === void 0 ? void 0 : _b.value;
    var procPoints = (_c = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'averageProcTimeMapColorGradation'; })) === null || _c === void 0 ? void 0 : _c.value;
    var officeMaped = (_d = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'sueoOfficeMaped'; })) === null || _d === void 0 ? void 0 : _d.value;
    var queuelessColor = (_e = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'notSueoOfficeColor'; })) === null || _e === void 0 ? void 0 : _e.value;
    var fieldName = ((_f = paramsData === null || paramsData === void 0 ? void 0 : paramsData.find(function (el) { return el.param === 'nameOfAdditionalField'; })) === null || _f === void 0 ? void 0 : _f.value) || '';
    if (waitePoints && procPoints) {
        timePoints = {
            wait: JSON.parse(waitePoints),
            proc: JSON.parse(procPoints),
        };
    }
    var checkOffice = function (e, ymap, offices) {
        if (offices && offices.length > 0) {
            var id_1 = Number(e.get('objectId'));
            var office = offices.find(function (el) { return el.office_id === id_1; });
            var element_1 = document.getElementById(String(id_1));
            if (element_1) {
                element_1.scrollIntoView({ behavior: 'smooth', block: 'center' });
                element_1.classList.add('glow');
                setTimeout(function () {
                    element_1.classList.remove('glow');
                }, 3500);
            }
            if (office && ymap) {
                var coordinates = office.Office.gpsmark.replace(/[()]/g, '').split(',').map(function (el) { return Number(el); });
                ymap.setCenter(coordinates, 15);
            }
        }
    };
    var checkOfficeCard = function (office) {
        var coordinates = office.Office.gpsmark.replace(/[()]/g, '').split(',').map(function (el) { return Number(el); });
        yandexMapObj === null || yandexMapObj === void 0 ? void 0 : yandexMapObj.setCenter(coordinates, 15);
    };
    var onMapInit = function () {
        map = new window.ymaps.Map('officeMap', {
            center: [37.64, 55.76],
            zoom: 9,
            controls: [],
        }, {
            suppressMapOpenBlock: true,
            autoFitToViewport: 'always',
            yandexMapDisablePoiInteractivity: true,
        });
        setYandexMapObj(map);
    };
    var addMapPoints = function () {
        var _a;
        var objManager = new window.ymaps.ObjectManager({
            clusterize: false,
            // gridSize: 130,
        });
        objManager.objects.events.add('click', function (e) { return checkOffice(e, yandexMapObj, data); });
        var pointsData = {
            type: 'FeatureCollection',
            features: [],
        };
        (_a = (officeMaped !== '0'
            ? data
            : data === null || data === void 0 ? void 0 : data.filter(function (el) { return el.sync_status !== 3; }))) === null || _a === void 0 ? void 0 : _a.filter(function (office) { return office.sync_status !== 2; }).forEach(function (el) {
            var coordinates = el.Office.gpsmark.replace(/[()]/g, '').split(',');
            pointsData.features.push({
                type: 'Feature',
                id: el.office_id,
                geometry: {
                    type: 'Point',
                    coordinates: coordinates,
                },
                properties: {
                    hintContent: hint(el),
                },
                options: {
                    preset: __assign({ iconLayout: 'default#image' }, getMapIcon(el.avg_wait_time, el.avg_proc_time, timePoints, el.sync_status !== 1, el.sync_status === 3, String(queuelessColor))),
                },
            });
        });
        objManager.add(JSON.stringify(pointsData));
        yandexMapObj === null || yandexMapObj === void 0 ? void 0 : yandexMapObj.geoObjects.add(objManager);
        if (yandexMapObj && yandexMapObj.geoObjects.getLength() > 3) {
            yandexMapObj === null || yandexMapObj === void 0 ? void 0 : yandexMapObj.geoObjects.splice(0, 1);
        }
    };
    var toggleFullscreen = function () {
        if (!fullscreen) {
            document.documentElement.requestFullscreen();
        }
        else {
            document.exitFullscreen();
        }
        setFullscreen(function (prevState) { return !prevState; });
    };
    var refreshMap = function () {
        if (yandexMapObj) {
            var bounds = yandexMapObj.geoObjects.getBounds();
            if (bounds !== null) {
                yandexMapObj.setBounds(bounds);
            }
        }
    };
    useEffect(function () {
        if (!isLoading && yandexMapObj) {
            addMapPoints();
        }
    }, [data, yandexMapObj]);
    useEffect(function () {
        if (!mapInit) {
            YMap.init(function () {
                window.ymaps.ready(onMapInit);
            });
            AppStore.update(function (state) { state.mapInit = true; });
        }
        else {
            window.ymaps.ready(onMapInit);
        }
    }, []);
    useEffect(function () {
        if (!isLoading) {
            refreshMap();
        }
    }, [yandexMapObj]);
    useEffect(function () {
        refetch();
    }, [sortValue, sortType]);
    return (<>
      <h2 className="page__header">Мониторинг отделений</h2>
      <div className={fullscreen ? 'fullscreen' : undefined}>
        <Row align="stretch">
          <Col span={19}>
            <div id="officeMap" className="office__map">
              <div className="fullscreen__button__box">
                <button className="fullscreen__button" type="button" onClick={toggleFullscreen}>
                  {fullscreen ? <FSoff /> : <FSon />}
                </button>
                <button className="fullscreen__button" type="button" onClick={refreshMap}>
                  <Popover content="Возврат карты к общему виду">
                    <EnvironmentFilled style={{ fontSize: '36px', color: '#05588f' }}/>
                  </Popover>
                </button>
              </div>
            </div>
          </Col>
          <Col span={5}>
            {!isLoading && data && (<OfficeList fullscreen={fullscreen} timePoints={timePoints} checkOffice={checkOfficeCard} offices={data} sort={[sortValue, sortType]} setSortType={setSortType} setSortValue={setSortValue} fieldName={fieldName}/>)}
          </Col>
        </Row>
      </div>
    </>);
}
export default Map;
