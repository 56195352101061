import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getInfoTableColumns } from '@/pages/Reports/officeReport/tableColumns';
import { seqTimeFormat } from '@/utils/constants';
import report from '@/utils/rest/report';
function OfficeInfo(_a) {
    var officeId = _a.officeId, queue = _a.queue, dates = _a.dates;
    var _b = useQuery(['office', officeId, queue, dates], function () { return report.getQueue(queue.queue_id, officeId, dates && dates[0] ? dates[0].format(seqTimeFormat) : dayjs().startOf('month').format(seqTimeFormat), dates && dates[1] ? dates[1].format(seqTimeFormat) : dayjs().endOf('day').format(seqTimeFormat)); }), data = _b.data, isLoading = _b.isLoading;
    return (<div>
      <Table rowKey="id" dataSource={data} loading={isLoading} columns={getInfoTableColumns()}/>
    </div>);
}
export default OfficeInfo;
