import React from 'react';
import EditableCell from '@/components/editableCell';
var getColumns = function (onChange) { return [
    {
        title: 'Параметр',
        dataIndex: 'param',
    },
    {
        title: 'Описание',
        dataIndex: 'description',
    },
    {
        title: 'Значение',
        dataIndex: 'value',
        width: 400,
        render: function (_, row) { return <EditableCell onChange={onChange} row={row} type={row.param_type}/>; },
    },
]; };
export default getColumns;
