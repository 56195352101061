import React from 'react';
import { Table } from 'antd';
import { useMutation, useQuery } from 'react-query';
import getColumns from '@/pages/Archive/tableColumns';
import { apiRoot } from '@/utils/constants';
import report from '@/utils/rest/report';
function Archive() {
    var _a = useQuery('archive', report.getArchive, {
        refetchInterval: 5000,
    }), isLoading = _a.isLoading, data = _a.data, refetch = _a.refetch;
    var deleteExport = useMutation({
        mutationFn: function (variables) { return report.deleteExport(variables); },
        onSuccess: function () {
            refetch();
        },
    }).mutate;
    var downloadFile = function (row) {
        var link = document.createElement('a');
        link.href = "".concat(apiRoot, "/report").concat(row.url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var handleDelete = function (row) {
        deleteExport(row);
    };
    return (<>
      <h1 className="page__header">Архив</h1>
      <Table rowKey="id" dataSource={data} columns={getColumns(downloadFile, handleDelete)} loading={isLoading}/>
    </>);
}
export default Archive;
