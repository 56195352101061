var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Button, Calendar, Card, Col, Collapse, Drawer, Row, Select, Skeleton, Statistic, } from 'antd';
import dayjs from 'dayjs';
import { useMutation, useQuery } from 'react-query';
import CopySlot from '@/pages/PreRecord/components/copySlot';
import CreateSlot from '@/pages/PreRecord/components/createSlot';
import DeleteSlot from '@/pages/PreRecord/components/deleteSlot';
import RewriteSlot from '@/pages/PreRecord/components/rewriteSlot';
import SlotCard from '@/pages/PreRecord/components/slotCard';
import preorder from '@/utils/rest/preorder';
function SingleOfficeSlots() {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), openDrawer = _b[0], setOpenDrawer = _b[1];
    var _c = useState(dayjs().month()), currentMonth = _c[0], setCurrentMonth = _c[1];
    var _d = useState(dayjs().year()), currentYear = _d[0], setCurrentYear = _d[1];
    var _e = useState(), currentOffice = _e[0], setCurrentOffice = _e[1];
    var _f = useState({
        date: dayjs(),
        dayId: 0,
    }), selectedDate = _f[0], setSelectedDate = _f[1];
    var _g = useState(), currentSlots = _g[0], setCurrentSlots = _g[1];
    var _h = useState(), queuesData = _h[0], setQueuesData = _h[1];
    var _j = useQuery('preorderOffices', preorder.getOffices), offices = _j.data, isOfficeLoading = _j.isLoading;
    var _k = useQuery('preorderQueues', function () { return preorder.getQueues(currentOffice); }, {
        enabled: false,
        cacheTime: 0,
        onSuccess: function (data) {
            data.forEach(function (el) {
                el.slots = [];
            });
            data.push({
                qId: 0,
                qFullName: 'Универсальные слоты для записи',
                slots: [],
            });
            setQueuesData(data);
        },
    }), queues = _k.data, refetchQueues = _k.refetch;
    var _l = useQuery('preorderSlots', function () { return preorder.getSlots(currentOffice, selectedDate.date); }, {
        enabled: false,
        cacheTime: 0,
        onSuccess: function (data) {
            if (queuesData) {
                queuesData.forEach(function (queue) {
                    var qSlots = data.filter(function (el) { return el.q_id === queue.qId; });
                    queue.slots = __spreadArray([], qSlots, true);
                });
                if (currentSlots) {
                    var newSlots = data.filter(function (slot) {
                        var idx = currentSlots.findIndex(function (el) { return el.calendarCellId === slot.calendarCellId; });
                        return idx !== -1;
                    });
                    setCurrentSlots(newSlots);
                }
                setQueuesData(queuesData);
                setLoading(false);
            }
        },
    }), slots = _l.data, isSlotLoading = _l.isLoading, isSlotFetching = _l.isFetching, refetch = _l.refetch;
    var _m = useMutation({
        mutationFn: function (variables) { return preorder.getDays(variables.officeId, variables.year); },
        onSuccess: function (data) {
            var currentDate = data.find(function (el) { return (el.dayOfMonth === selectedDate.date.date()
                && el.monthNumber === selectedDate.date.month() + 1); });
            if (currentDate) {
                setSelectedDate({
                    date: selectedDate.date,
                    dayId: currentDate.dayId,
                });
            }
            refetchQueues();
        },
    }), days = _m.data, getDays = _m.mutate;
    var drawerHeaders = {
        slots: "\u0421\u043B\u043E\u0442\u044B \u043D\u0430 ".concat(currentSlots && dayjs(currentSlots[0].pastart_on).format('DD.MM.YYYY HH.mm')),
        create: 'Добавление слотов',
        copy: "\u041A\u043E\u043F\u0438\u0440\u043E\u0432\u0430\u043D\u0438\u0435 \u0441\u043B\u043E\u0442\u043E\u0432 \u0437\u0430 ".concat(selectedDate.date.format('DD.MM.YYYY')),
        delete: "\u0423\u0434\u0430\u043B\u0435\u043D\u0438\u0435 \u0441\u043B\u043E\u0442\u043E\u0432 \u0437\u0430 ".concat(selectedDate.date.format('DD.MM.YYYY')),
        rewrite: "\u041F\u0435\u0440\u0435\u0437\u0430\u043F\u0438\u0441\u044C \u0441\u043B\u043E\u0442\u043E\u0432 \u043D\u0430 ".concat(selectedDate.date.format('DD.MM.YYYY')),
    };
    var selectDay = function (date) {
        var currentDay = days === null || days === void 0 ? void 0 : days.find(function (el) { return (el.dayOfMonth === date.date() && el.monthNumber === (date.month() + 1) && el.yearNumber === date.year()); });
        setSelectedDate({ date: date, dayId: (currentDay === null || currentDay === void 0 ? void 0 : currentDay.dayId) || 0 });
    };
    var selectTime = function (el) {
        setCurrentSlots(el);
        setOpenDrawer('slots');
    };
    var changeMonth = function (date) {
        selectDay(date);
        setCurrentMonth(date.month());
        setCurrentYear(date.year());
    };
    var renderSlots = function (queue) {
        var daySlots = queue.slots.filter(function (slot) { return dayjs(slot.thedate).format('YYYY.MM.DD') === selectedDate.date.format('YYYY.MM.DD'); });
        var slotsObj = {};
        var all = 0;
        var occupied = 0;
        var free = 0;
        daySlots.forEach(function (slot) {
            all += 1;
            if (slot.registered) {
                occupied += 1;
            }
            else {
                free += 1;
            }
            var idx = Object.keys(slotsObj).findIndex(function (el) { return el === dayjs(slot.pastart_on).format('HH:mm'); });
            if (idx === -1) {
                slotsObj[dayjs(slot.pastart_on).format('HH:mm')] = [slot];
            }
            else {
                slotsObj[dayjs(slot.pastart_on).format('HH:mm')].push(slot);
            }
        });
        return {
            key: queue.qId,
            label: "".concat(queue.qFullName, " (\u0437\u0430\u043F\u0438\u0441\u0430\u043D\u043E: ").concat(occupied, " / \u0441\u0432\u043E\u0431\u043E\u0434\u043D\u043E: ").concat(free, " / \u0432\u0441\u0435\u0433\u043E: ").concat(all, ")"),
            children: (<div className="preorder__queue__info">
          {(Object.keys(slotsObj).map(function (el) { return (<Card key={el} title={el} extra={<Button type="dashed" onClick={function () { return selectTime(slotsObj[el]); }}>Показать</Button>} style={{ width: '240px' }}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Statistic title="Всего" value={slotsObj[el].length}/>
                  </Col>
                  <Col span={12}>
                    <Statistic title="Свободно" value={slotsObj[el].filter(function (slot) { return !slot.registered; }).length}/>
                  </Col>
                </Row>
              </Card>); }))}
        </div>),
        };
    };
    var cellRender = function (date) {
        var cell = document.querySelector("[title=\"".concat(date.format('YYYY-MM-DD'), "\"]"));
        cell === null || cell === void 0 ? void 0 : cell.classList.remove('free__cell', 'busy__slot');
        var dateSlots = slots === null || slots === void 0 ? void 0 : slots.filter(function (slot) {
            var idx = queuesData === null || queuesData === void 0 ? void 0 : queuesData.findIndex(function (queue) { return (queue.qId === (slot.q_id || 0)); });
            return dayjs(slot.thedate).format('YYYY.MM.DD') === date.format('YYYY.MM.DD') && idx !== -1;
        });
        if (!slots || selectedDate.date.month() !== date.month()) {
            return '';
        }
        var past = dayjs().startOf('day').isAfter(date);
        var all = (dateSlots === null || dateSlots === void 0 ? void 0 : dateSlots.length) || 0;
        var free = (dateSlots === null || dateSlots === void 0 ? void 0 : dateSlots.filter(function (slot) { return !slot.registered; }).length) || 0;
        var registered = (dateSlots === null || dateSlots === void 0 ? void 0 : dateSlots.filter(function (slot) { return slot.registered; }).length) || 0;
        if (free > 0 && !past) {
            cell === null || cell === void 0 ? void 0 : cell.classList.add('free__cell');
        }
        else if (all > 0 && free === 0 && !past) {
            cell === null || cell === void 0 ? void 0 : cell.classList.add('busy__slot');
        }
        return (<div className="preorder__calendar__cell">
        <p className="preorder__calendar__cell__text">
          Всего:
          {' '}
          {all}
        </p>
        <p className="preorder__calendar__cell__text">
          Свободно:
          {' '}
          {free}
        </p>
        <p className="preorder__calendar__cell__text">
          Занято:
          {' '}
          {registered}
        </p>
      </div>);
    };
    var selectOffice = function (officeId) {
        setQueuesData(undefined);
        setLoading(true);
        setCurrentOffice(officeId);
        getDays({ officeId: officeId, year: currentYear });
    };
    var closeDrawer = function () {
        setCurrentSlots(undefined);
        setOpenDrawer(false);
    };
    var renderDrawer = function () {
        switch (openDrawer) {
            case 'slots':
                return (<div className="preorder__slot__box">
            {queuesData && (currentSlots === null || currentSlots === void 0 ? void 0 : currentSlots.map(function (slot) { return (<SlotCard officeId={currentOffice} key={slot.pincode} slot={slot} closeDrawer={closeDrawer} refetchSlots={refetch} queues={queuesData} isLoading={isSlotFetching}/>); }))}
          </div>);
            case 'create':
                return (<CreateSlot officeId={currentOffice} days={days} queues={queuesData} refetch={refetch} closeDrawer={closeDrawer} getDays={getDays}/>);
            case 'copy':
                return (<CopySlot officeId={currentOffice} queues={queuesData} dayId={selectedDate.dayId} refetch={refetch} closeDrawer={closeDrawer}/>);
            case 'delete':
                return (<DeleteSlot officeId={currentOffice} queues={queuesData} dayId={selectedDate.dayId} refetch={refetch} closeDrawer={closeDrawer}/>);
            case 'rewrite':
                return (<RewriteSlot officeId={currentOffice} queues={queuesData} dayId={selectedDate.dayId} refetch={refetch} closeDrawer={closeDrawer}/>);
            default:
                return '';
        }
    };
    useEffect(function () {
        if (currentOffice && queues) {
            refetch();
        }
    }, [currentOffice, currentMonth, queues, currentYear]);
    useEffect(function () {
        if (currentOffice) {
            getDays({ officeId: currentOffice, year: currentYear });
        }
    }, [currentYear]);
    return isOfficeLoading && !slots ? <Skeleton /> : (<div className="preorder">
      <Drawer open={!!openDrawer} onClose={closeDrawer} title={drawerHeaders[String(openDrawer)]} width="auto">
        {!isSlotLoading && renderDrawer()}
      </Drawer>
      <div className="preorder__calendar">
        <Calendar style={{ padding: '10px' }} onSelect={selectDay} cellRender={cellRender} onPanelChange={changeMonth} mode="month" headerRender={function (_a) {
            var value = _a.value, onChange = _a.onChange;
            var months = dayjs.months();
            var year = value.year();
            var yearOptions = [];
            for (var i = year - 10; i < year + 10; i += 1) {
                yearOptions.push(<Select.Option key={i} value={i}>{i}</Select.Option>);
            }
            return (<div className="preorder__calendar__header">
                  <Select style={{ width: '140px' }} value={value.month()} onChange={function (val) {
                    var now = value.clone().month(val);
                    onChange(now);
                }}>
                    {months.map(function (month, idx) { return (<Select.Option key={month} value={idx}>{month}</Select.Option>); })}
                  </Select>
                  <Select style={{ width: '100px' }} value={value.year()} onChange={function (val) {
                    var now = value.clone().year(val);
                    onChange(now);
                }}>
                    {yearOptions}
                  </Select>
                </div>);
        }}/>
      </div>
      <div className="preorder__info">
        <div className="preorder__select__box">
          <Select showSearch optionFilterProp="label" onSelect={selectOffice} style={{ width: '100%' }} placeholder="Выберите офис">
            {offices === null || offices === void 0 ? void 0 : offices.map(function (office) { return (<Select.Option key={office.id} value={office.id} label={office.name}>{office.name}</Select.Option>); })}
          </Select>
          <Button disabled={!currentOffice || loading} onClick={function () { return setOpenDrawer('create'); }}>
            Добавить слот
          </Button>
          <Button disabled={!currentOffice || loading} onClick={function () { return setOpenDrawer('copy'); }}>
            Копировать слоты
          </Button>
          <Button disabled={!currentOffice || loading} onClick={function () { return setOpenDrawer('delete'); }}>
            Удалить слоты
          </Button>
          <Button disabled={!currentOffice || loading} onClick={function () { return setOpenDrawer('rewrite'); }}>
            Перезаписать слот
          </Button>
        </div>
        {loading && <Skeleton active/>}
        {!!queuesData && (<Collapse items={queuesData.filter(function (el) {
                var idx = el.slots.findIndex(function (slot) { return dayjs(slot.thedate).format('YYYY.MM.DD') === selectedDate.date.format('YYYY.MM.DD'); });
                return idx !== -1;
            }).map(function (queue) { return renderSlots(queue); })}/>)}
      </div>
    </div>);
}
export default SingleOfficeSlots;
