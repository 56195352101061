import * as process from 'process';
var dev = process.env.NODE_ENV === 'development';
export var apiRoot = dev ? "http://".concat(window.location.hostname, ":45555") : '';
export var apiAdmin = dev ? "http://".concat(window.location.hostname, ":45555/admin") : '/admin';
export var timeDateFormat = 'HH:mm:ss DD.MM.YYYY';
export var dateTimeFormat = 'DD.MM.YYYY HH:mm';
export var timeFormat = 'HH:mm:ss';
export var seqTimeFormat = 'DD.MM.YYYY HH:mm';
export var colors = {
    normal: '#5da032',
    warning: '#20B4C8',
    error: '#e50e51',
    offline: '#000',
};
