import React from 'react';
import { Tabs } from 'antd';
import reportItems from '@/pages/Reports/reportItems';
function Reports() {
    return (<>
      <h2 className="page__header">Отчёты</h2>
      <Tabs defaultActiveKey="ticketReport" items={reportItems}/>
    </>);
}
export default Reports;
