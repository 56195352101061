import React from 'react';
import { Button, Form, Input, Select, } from 'antd';
function GroupEdit(_a) {
    var toggleDrawer = _a.toggleDrawer, officeList = _a.officeList, onFinish = _a.onFinish, currentGroup = _a.currentGroup;
    var filter = function (value, option) { return (option ? option.children.toLowerCase().includes(value) : false); };
    var defaultValues = {
        name: '',
        description: '',
        offices: [],
    };
    if (currentGroup) {
        var offices_1 = [];
        currentGroup.offices.forEach(function (el) {
            offices_1.push(Number(el.office_id));
        });
        defaultValues.name = currentGroup.name;
        defaultValues.description = currentGroup.description;
        defaultValues.offices = offices_1;
    }
    return (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish} initialValues={defaultValues}>
      <Form.Item label="Наименование группировки" name="name" rules={[{ required: true, message: 'Введите наименование группировки' }]}>
        <Input placeholder="Введите название группировки"/>
      </Form.Item>
      <Form.Item label="Описание группировки" name="description">
        <Input placeholder="Введите описание группировки"/>
      </Form.Item>
      <Form.Item label="Офисы" name="offices">
        <Select mode="multiple" placeholder="Выберите офисы" filterOption={filter}>
          {officeList.map(function (office) { return (<Select.Option key={office.id} value={office.id}>{office.name}</Select.Option>); })}
        </Select>
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div className="form__buttons">
          <Button type="primary" htmlType="submit">Сохранить</Button>
          <Button onClick={toggleDrawer}>Отменить</Button>
        </div>
      </Form.Item>
    </Form>);
}
export default GroupEdit;
