import React from 'react';
import { UserOutlined, AreaChartOutlined, BankOutlined, HomeOutlined, CopyOutlined, EnvironmentOutlined, SettingOutlined, DatabaseOutlined, MailOutlined, UnorderedListOutlined, BlockOutlined, GroupOutlined, FormOutlined,
// TableOutlined,
// FileExclamationOutlined,
 } from '@ant-design/icons';
import paths from '@/utils/paths';
var iconSize = '20px';
export default [
    {
        key: paths.monitoring,
        icon: <AreaChartOutlined style={{ fontSize: iconSize }}/>,
        label: 'Мониторинг',
    },
    // {
    //   key: paths.tableMonitoring,
    //   icon: <TableOutlined />,
    //   label: 'Табличный мониторинг',
    // },
    {
        key: paths.map,
        icon: <EnvironmentOutlined style={{ fontSize: iconSize }}/>,
        label: 'Карта',
    },
    {
        key: paths.reports,
        icon: <CopyOutlined style={{ fontSize: iconSize }}/>,
        label: 'Отчёты',
    },
    {
        key: paths.archive,
        icon: <DatabaseOutlined style={{ fontSize: iconSize }}/>,
        label: 'Архив',
    },
    {
        key: paths.widgets,
        icon: <BlockOutlined style={{ fontSize: iconSize }}/>,
        label: 'Виджеты',
    },
    {
        key: paths.prerecord,
        icon: <FormOutlined style={{ fontSize: iconSize }}/>,
        label: <p style={{ lineHeight: 'normal' }}>
    Предварительная
    <br />
    {' '}
    запись
  </p>,
    },
    {
        key: 'settings',
        icon: <SettingOutlined style={{ fontSize: iconSize }}/>,
        label: 'Настройки',
        private: 'true',
        children: [
            {
                key: paths.companies,
                icon: <BankOutlined style={{ fontSize: iconSize }}/>,
                label: 'Компании',
            },
            {
                key: paths.offices,
                icon: <HomeOutlined style={{ fontSize: iconSize }}/>,
                label: 'Офисы',
            },
            {
                key: paths.groups,
                icon: <GroupOutlined style={{ fontSize: iconSize }}/>,
                label: 'Группировки',
            },
            {
                key: paths.users,
                icon: <UserOutlined style={{ fontSize: iconSize }}/>,
                label: 'Пользователи',
            },
            {
                key: paths.mailing,
                icon: <MailOutlined style={{ fontSize: iconSize }}/>,
                label: 'Адреса для рассылки',
            },
            {
                key: paths.settings,
                icon: <SettingOutlined style={{ fontSize: iconSize }}/>,
                label: 'Параметры',
            },
            {
                key: paths.logs,
                icon: <UnorderedListOutlined style={{ fontSize: iconSize }}/>,
                label: 'Логи',
            },
        ],
    },
    // {
    //   key: paths.assignment,
    //   icon: <FileExclamationOutlined />,
    //   label: 'Распределение талонов',
    // },
    // {
    //   key: paths.groups,
    //   icon: <GroupOutlined />,
    //   label: 'Группировка',
    // },
];
