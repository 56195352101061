import React, { useState } from 'react';
import { ArrowsAltOutlined, ShrinkOutlined, SettingOutlined, DeleteOutlined, } from '@ant-design/icons';
import { Button, Card, Drawer, FloatButton, Form, Input, Modal, notification, Popconfirm, Switch, } from 'antd';
import Cookies from 'js-cookie';
import { useMutation, useQuery } from 'react-query';
import { AppStore } from '@/store';
import widgets from '@/utils/rest/widgets';
function Widgets() {
    var _a = useQuery('widgets', widgets.getAll), data = _a.data, isLoading = _a.isLoading, refetch = _a.refetch;
    var _b = useState(false), openDrawer = _b[0], setOpenDrawer = _b[1];
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = useState(), currentFrame = _d[0], setCurrentFrame = _d[1];
    var _e = AppStore.useState(function (state) { return state; }), updateSider = _e.updateSider, updateIndicators = _e.updateIndicators;
    var token = Cookies.get('token');
    var toggleDrawer = function () {
        setOpenDrawer(function (prevState) { return !prevState; });
        if (openDrawer) {
            setCurrentFrame(undefined);
        }
    };
    var toggleWidget = function (widget) {
        if (showModal) {
            setCurrentFrame(undefined);
        }
        else {
            setCurrentFrame(widget);
        }
        setShowModal(function (prevState) { return !prevState; });
    };
    var createWidget = useMutation({
        mutationFn: function (variables) { return widgets.create(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Сервис успешно добавлен',
            });
            toggleDrawer();
            refetch();
            if (updateSider) {
                updateSider();
            }
            if (updateIndicators) {
                updateIndicators();
            }
        },
        onError: function () {
            notification.error({
                message: 'Не удалось добавить сервис',
            });
        },
    }).mutate;
    var updateWidget = useMutation({
        mutationFn: function (variables) { return widgets.update(variables); },
        onSuccess: function () {
            notification.success({
                message: 'Сервис успешно обновлён',
            });
            toggleDrawer();
            refetch();
            if (updateSider) {
                updateSider();
            }
            if (updateIndicators) {
                updateIndicators();
            }
        },
        onError: function () {
            notification.error({
                message: 'Не удалось обновить сервис',
            });
        },
    }).mutate;
    var deleteMutate = useMutation({
        mutationFn: function (id) { return widgets.delete(id); },
        onSuccess: function () {
            notification.success({
                message: 'Сервис удалён',
            });
            toggleDrawer();
            refetch();
            if (updateSider) {
                updateSider();
            }
            if (updateIndicators) {
                updateIndicators();
            }
        },
        onError: function () {
            notification.error({
                message: 'Не удалось удалить сервис',
            });
        },
    }).mutate;
    var editWidget = function (widget) {
        setCurrentFrame(widget);
        setOpenDrawer(true);
    };
    var deleteWidget = function () {
        if (currentFrame) {
            deleteMutate(currentFrame.id);
        }
    };
    var onFinish = function (formData) {
        if (currentFrame) {
            formData.id = currentFrame.id;
            updateWidget(formData);
        }
        else {
            createWidget(formData);
        }
    };
    return (<>
      <h2 className="page__header">Сервисы</h2>
      <div className="page__content">
        <Button className="add__button" type="primary" onClick={toggleDrawer}>Добавить</Button>
        <Drawer extra={currentFrame && (<Popconfirm title="Удаление сервиса" description="Вы действительно хотите удалить сервис" onConfirm={deleteWidget} okText="Да" cancelText="Нет">
              <DeleteOutlined style={{ fontSize: '24px' }}/>
            </Popconfirm>)} destroyOnClose width={600} open={openDrawer} onClose={toggleDrawer}>
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish} initialValues={currentFrame} labelWrap>
            <Form.Item label="Название сервиса" name="name" rules={[{ required: true }]}>
              <Input placeholder="Введите название сервиса"/>
            </Form.Item>
            <Form.Item label="URL" name="frame_url" rules={[{ required: true }]}>
              <Input placeholder="Введите URL сервиса"/>
            </Form.Item>
            <Form.Item label="Описание" name="description">
              <Input placeholder="Введите описание"/>
            </Form.Item>
            {!!currentFrame && (<>
                <Form.Item label={(<p>
                      Показывать
                      <br />
                      {' '}
                      в основном меню
                    </p>)} name="show" valuePropName="checked">
                  <Switch />
                </Form.Item>
                {!!currentFrame.meta && (<Form.Item label="Показывать индикатор" name="show_indication" valuePropName="checked">
                    <Switch />
                  </Form.Item>)}
              </>)}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <div className="form__buttons">
                <Button type="primary" htmlType="submit">Сохранить</Button>
                <Button onClick={toggleDrawer}>Отменить</Button>
              </div>
            </Form.Item>
          </Form>
        </Drawer>
        <div>
          {!isLoading && (data.length === 0 ? <h2>Нет виджетов</h2> : (<div className="widgets__box">
                {data.map(function (el) { return (<Card key={el.name} title={el.name} style={{ width: '250px' }} extra={(<div className="widgets__button__box">
                        <button className="widgets__button" type="button">
                          <SettingOutlined style={{ fontSize: '24px' }} onClick={function () { return editWidget(el); }}/>
                        </button>
                        <button className="widgets__button" type="button" onClick={function () { return toggleWidget(el); }}>
                          <ArrowsAltOutlined style={{ fontSize: '24px' }}/>
                        </button>
                      </div>)}>
                    <p>{el.description}</p>
                  </Card>); })}
              </div>))}
        </div>
        <Modal footer={null} closeIcon={false} open={showModal} destroyOnClose width="90vw" onCancel={function () { return toggleWidget(); }} centered>
          <div style={{ height: '90vh' }}>
            <iframe src={"".concat(currentFrame === null || currentFrame === void 0 ? void 0 : currentFrame.frame_url, "?token=").concat(token)} title={currentFrame === null || currentFrame === void 0 ? void 0 : currentFrame.name} width="99%" height="100%" id="my_frame"/>
          </div>
          <FloatButton onClick={function () { return toggleWidget(); }} icon={<ShrinkOutlined />}/>
        </Modal>
      </div>
    </>);
}
export default Widgets;
