import React from 'react';
import { Button, Form, Input, Select, } from 'antd';
function UserEdit(_a) {
    var user = _a.user, cancel = _a.cancel, handleUser = _a.handleUser;
    var roles = [
        { label: 'Администратор', value: 'admin' },
        { label: 'Читатель отчётов', value: 'reader' },
        { label: 'Пользователь', value: 'user' },
        { label: 'Анкетировщик', value: 'checklister' },
    ];
    return (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={user} onFinish={handleUser}>
      <Form.Item label="Фамилия Имя Отчество" name="fullname" rules={[{ required: true, message: 'Введите ФИО пользователя' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Адрес электронной почты" name="email">
        <Input />
      </Form.Item>
      <Form.Item label="Роль" name="role" rules={[{ required: true, message: 'Выберите роль пользователя' }]}>
        <Select options={roles}/>
      </Form.Item>
      <Form.Item label="Логин" name="login" rules={[{ required: true, message: 'Введите логин пользователя' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Пароль" name="password" rules={!user ? [{ required: true, message: 'Введите пароль пользователя' }] : undefined}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div className="form__buttons">
          <Button type="primary" htmlType="submit">Сохранить</Button>
          <Button onClick={cancel}>Отменить</Button>
        </div>
      </Form.Item>
    </Form>);
}
export default UserEdit;
