import React from 'react';
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { isAdmin } from '@/utils/checkRole';
import { dateTimeFormat } from '@/utils/constants';
var getColumns = function (handleEdit, handleDelete, role) {
    var columns = [
        {
            title: 'Дата добавления',
            dataIndex: 'createdAt',
            render: function (value) { return moment(value).format(dateTimeFormat); },
        },
        {
            title: 'Название',
            dataIndex: 'name',
        },
        {
            title: 'Описание компании',
            dataIndex: 'description',
        },
        {
            title: 'Количество отделений',
            dataIndex: 'office_count',
            align: 'center',
        },
    ];
    if (isAdmin(role)) {
        columns.push({
            title: 'Действия',
            dataIndex: 'actions',
            render: function (_, row) { return (<div className="table__button__box">
          <button className="table__button" onClick={function () { return handleEdit(row); }} type="submit">
            <EditTwoTone style={{ fontSize: '28px' }}/>
          </button>
          <button className="table__button" onClick={function () { return handleDelete(row.id); }} type="submit">
            <DeleteTwoTone twoToneColor="#f70707" style={{ fontSize: '28px' }}/>
          </button>
        </div>); },
        });
    }
    return columns;
};
export default getColumns;
