import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Select, } from 'antd';
function OfficeEdit(_a) {
    var office = _a.office, cancel = _a.cancel, onFinish = _a.onFinish, companies = _a.companies, loading = _a.loading, checkOffice = _a.checkOffice, newOffice = _a.newOffice;
    var _b = useState(true), haveSueo = _b[0], setHaveSueo = _b[1];
    var options = [];
    var form = Form.useForm()[0];
    var isSueo = Form.useWatch('isSueo', form);
    if ((office === null || office === void 0 ? void 0 : office.info) && typeof (office === null || office === void 0 ? void 0 : office.info) !== 'object') {
        office.info = JSON.parse(String(office.info));
    }
    if (companies) {
        companies.forEach(function (el) {
            options.push({
                label: el.name,
                value: el.id,
            });
        });
    }
    var toggleSueo = function (e) {
        setHaveSueo(e.target.checked);
    };
    useEffect(function () {
        if (newOffice) {
            form.setFieldsValue(newOffice);
        }
    }, [newOffice]);
    useEffect(function () {
        if (isSueo !== undefined) {
            setHaveSueo(isSueo);
        }
    }, [isSueo]);
    return (<>
      <Row style={{ marginBottom: '10px' }}>
        <Col span={8}><p style={{ textAlign: 'end', paddingRight: '5px' }}>Наличие электронной очереди</p></Col>
        <Col span={16}><Checkbox onChange={toggleSueo} checked={haveSueo}/></Col>
      </Row>
      {haveSueo ? (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={office} onFinish={(office || newOffice || !haveSueo) ? onFinish : checkOffice} form={form}>
          {(newOffice || office || !haveSueo) && (<>
              <Form.Item label="Название офиса" name="name" rules={[{ required: true, message: 'Введите название офиса' }]}>
                <Input placeholder="Введите название офиса"/>
              </Form.Item>
              <Form.Item label="Описание офиса" name="description">
                <Input placeholder="Введите описание офиса"/>
              </Form.Item>
              <Form.Item label="Адрес офиса" name="situation" rules={[{ required: true, message: 'Введите адрес офиса' }]}>
                <Input />
              </Form.Item>
            </>)}
          <Form.Item label="Компания" name="company_id" rules={[{ required: true, message: 'Выберите компанию' }]}>
            <Select options={options}/>
          </Form.Item>
          <Form.Item label="Адрес подключения" name="url" rules={[{ required: true, message: 'Введите адрес подключения' }]}>
            <Input placeholder="Введите адрес подключения"/>
          </Form.Item>
          <Form.Item label="Камеры">
            <Form.List name={['info', 'cameras']}>
              {function (fields, _a) {
                var add = _a.add, remove = _a.remove;
                return (<div style={{
                        display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10px',
                    }}>
                  {fields.map(function (field) { return (<div key={field.key} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Form.Item name={[field.name, 'url']} noStyle>
                        <Input placeholder="Ссылка на камеру"/>
                      </Form.Item>
                      <CloseOutlined onClick={function () {
                            remove(field.name);
                        }}/>
                    </div>); })}
                  <Button type="dashed" onClick={function () { return add(); }}>+ Добавить камеру</Button>
                </div>);
            }}
            </Form.List>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div className="form__buttons">
              <Button loading={loading} type="primary" htmlType="submit">
                {(office || newOffice)
                ? 'Сохранить'
                : 'Проверить'}
              </Button>
              <Button onClick={cancel}>Отменить</Button>
            </div>
          </Form.Item>
        </Form>) : (<Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={office} onFinish={onFinish}>
          <Form.Item label="Название офиса" name="name" rules={[{ required: true, message: 'Введите название офиса' }]}>
            <Input placeholder="Введите название офиса"/>
          </Form.Item>
          <Form.Item label="Описание офиса" name="description">
            <Input placeholder="Введите описание офиса"/>
          </Form.Item>
          <Form.Item label="Адрес офиса" name="situation" rules={[{ required: true, message: 'Введите адрес офиса' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Компания" name="company_id" rules={[{ required: true, message: 'Выберите компанию' }]}>
            <Select options={options}/>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <div className="form__buttons">
              <Button loading={loading} type="primary" htmlType="submit">Сохранить</Button>
              <Button onClick={cancel}>Отменить</Button>
            </div>
          </Form.Item>
        </Form>)}
    </>);
}
export default OfficeEdit;
