var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { CheckOutlined, DeleteOutlined, ClearOutlined, CopyOutlined, LoadingOutlined, } from '@ant-design/icons';
import { Card, Form, Input, Statistic, message, Select, notification, Button, Popover, } from 'antd';
import dayjs from 'dayjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMutation } from 'react-query';
import preorder from '@/utils/rest/preorder';
function SlotCard(_a) {
    var slot = _a.slot, officeId = _a.officeId, closeDrawer = _a.closeDrawer, refetchSlots = _a.refetchSlots, queues = _a.queues, isLoading = _a.isLoading;
    var _b = useState(), clientData = _b[0], setClientData = _b[1];
    var _c = message.useMessage(), messageApi = _c[0], contextHolder = _c[1];
    var registered = slot.registered, slotStart = slot.pastart_on;
    var isPast = dayjs().isAfter(slotStart);
    var clear = false;
    var onCopy = function () {
        messageApi.open({
            type: 'success',
            content: 'пин-код скопирован',
        });
    };
    var _d = useMutation({
        mutationFn: function (variables) { return preorder.registerSlot(variables.officeId, variables.slot); },
        onSuccess: function () {
            refetchSlots();
            closeDrawer();
            notification.success({
                message: !registered ? 'Слот зарегистрирован' : 'Слот обновлён',
            });
        },
        onError: function () {
            notification.error({
                message: 'Не удалось зарегистрировать слот',
            });
        },
    }), mutate = _d.mutate, isRegisterLoading = _d.isLoading;
    var _e = useMutation({
        mutationFn: function (variables) { return preorder.rewriteSlot(variables.officeId, variables.slotId); },
        onSuccess: function () {
            if (officeId && !clear) {
                slot = __assign(__assign({}, slot), clientData);
                mutate({ officeId: officeId, slot: slot });
            }
            else {
                clear = false;
                refetchSlots();
            }
        },
    }), rewriteSlotMutate = _e.mutate, isRewriteLoading = _e.isLoading;
    var deleteSlotMutate = useMutation({
        mutationFn: function (variables) { return preorder.deleteSlot(variables.officeId, variables.slotId); },
        onSuccess: function () {
            refetchSlots();
            closeDrawer();
        },
    }).mutate;
    if (slot.q_id === 0) {
        slot.q_id = undefined;
    }
    var handleInput = function (_value, values) {
        setClientData(values);
    };
    var registerSlot = function () {
        if (officeId) {
            slot = __assign(__assign({}, slot), clientData);
            mutate({ officeId: officeId, slot: slot });
        }
    };
    var overwriteSlot = function () {
        if (officeId) {
            rewriteSlotMutate({ officeId: officeId, slotId: slot.calendarCellId });
        }
    };
    var clearSlot = function () {
        clear = true;
        overwriteSlot();
    };
    var deleteSlot = function () {
        if (officeId) {
            deleteSlotMutate({ officeId: officeId, slotId: slot.calendarCellId });
        }
    };
    var actions = [
        <Popover content="Удалить слот">
      <Button onClick={deleteSlot} disabled={registered || isPast}>
        <DeleteOutlined style={{ fontSize: 20 }}/>
      </Button>
    </Popover>,
        <Popover content={registered ? 'Обновить слот' : 'Зарегистрировать слот'}>
      <Button onClick={slot.registered ? overwriteSlot : registerSlot} disabled={(!slot.q_id && !(clientData === null || clientData === void 0 ? void 0 : clientData.q_id)) || isPast}>
        {isRegisterLoading
                ? <LoadingOutlined style={{ fontSize: 20 }}/>
                : <CheckOutlined style={{ fontSize: 20 }}/>}
      </Button>
    </Popover>,
    ];
    if (registered) {
        actions.push(<Popover content="Очистить слот">
        <Button onClick={clearSlot} disabled={isPast}>
          {isLoading || isRewriteLoading
                ? <LoadingOutlined style={{ fontSize: 20 }}/>
                : <ClearOutlined style={{ fontSize: 20 }}/>}
        </Button>
      </Popover>);
    }
    return (<Card style={{ width: '340px' }} actions={actions}>
      <Form layout="vertical" onValuesChange={handleInput} initialValues={slot} disabled={isPast}>
        <Form.Item name="clientfio" label="ФИО">
          <Input />
        </Form.Item>
        <Form.Item name="clientphone" label="Телефон">
          <Input />
        </Form.Item>
        <Form.Item name="clientmail" label="email">
          <Input />
        </Form.Item>
        {!slot.q_id && (<Form.Item required name="q_id" label="Очередь">
            <Select placeholder="Выберите очеоедь">
              {queues.filter(function (el) { return el.qId !== 0; }).map(function (queue) { return (<Select.Option key={queue.qId}>{queue.qFullName}</Select.Option>); })}
            </Select>
          </Form.Item>)}
      </Form>
      {slot.registered && (<div className="preorder__slot__pin">
          <Statistic formatter={function (value) { return value; }} title="пин-код" value={slot.pincode}/>
          {slot.registered && (<CopyToClipboard text={String(slot.pincode)} onCopy={onCopy}>
              <CopyOutlined style={{ fontSize: 24, marginBottom: 10 }}/>
            </CopyToClipboard>)}
        </div>)}
      {contextHolder}
    </Card>);
}
export default SlotCard;
